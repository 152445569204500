
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import {down, up} from 'styled-breakpoints'
import {withRouter} from 'react-router-dom'




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
        this.input=[]
    }

    

    componentDidMount() {
    
    }


    render (){
        const {} = this.props
       
        

        return (
           <MainView>
               <div className='row'>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='mb-3'>
                                <Card>
                                    <CardHeader title="Заказчики"/>
                                        <CardContent>
                                        <p>Такая роль пользователей в системе принадлежит представителям компаний, с которыми ведется сотрудничество</p>
                                        <p>Каждому заказчику назначена одна компания</p>
                                        <p>В рамках этой компании создаются проекты</p>
                                        </CardContent>
                                </Card>
                            </div>
                            <div className='mb-3'>
                                <Card>
                                    <CardHeader title="Проекты"/>
                                        <CardContent>
                                        <p>Проект привязан к конкретному заказчику. Поэтому имеет прямую связь с компанией заказчика</p>
                                        <p>Проекту назначаются статусы его выполнения (может менять только администратор). Так же, можно задачь дату начала и дед-лайн</p>
                                        <p>Внутри проекта создаются задачи</p>
                                        </CardContent>
                                </Card>
                            </div>
                            <div className='mb-3'>
                                <Card>
                                    <CardHeader title="Чаты"/>
                                        <CardContent>
                                        <p>Переписку может начать только Администратор. Все пользователи системы, которые будут находиться в чате сразу получат доступ к тем чатам, в которых состоят</p>
                                        </CardContent>
                                </Card>
                            </div> 
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='mb-3'>
                                <Card>
                                    <CardHeader title="Задачи"/>
                                        <CardContent>
                                        <p>Изначально задача никому не назначена и имеет статус "Новая". В задаче нужно описать конкретные действия, которые требуются от исполнителя в рамках проекта, к которому принадлежит задача.</p>
                                        <p>Чтобы назначить исполнителя нужно нажать на "Назначить" в задаче. После у этого исполнителя появится задача в его задачах к выполнению со статусом "Передана" и комментарием, который укажет администратор. Для того, чтобы работать с задачей исполнителю требуется принять задачу в работу, тем самым подтверждая, что задачу он прочитал. Исполнитель может сам назначить другого исполнителя (например передать задачу на тест)</p>
                                        <p>Задачу может завершить только администратор</p>
                                        </CardContent>
                                </Card>
                            </div>
                            <div className='mb-3'>
                                <Card>
                                    <CardHeader title="Исполнители"/>
                                        <CardContent>
                                        <p>Исполнители, так же как и заказчики - создаются в разделе "База данных". У всех пользователей в системе есть общая структура данных, которая включает в себя такие стандартные значения как: ФИО, E-mail и т.д.</p>
                                        <p>Каждому исполнителю можно назначить несколько доступов к разным отделам. В каждом отделе исполнитель будет иметь разную должность. Например, дизайнер может так же отвечать за проведение финального тестирования приложения</p>
                                        </CardContent>
                                </Card>
                            </div>
                    </div>
                </div>
                
           </MainView>
        )



    }
}

const MainView = styled.div`
& .InfoBlock{
    display: flex;
    justify-content: center;
}
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

