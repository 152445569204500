
import { Check } from '@mui/icons-material';
import { Alert, Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {getBACKLOG} from '../../../actions/PageActions'
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import POP_Project from './POP_Project';
import POP_Task from './POP_Task';




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
           selectedCompanys:props.user.ROLE=='CLEN'?props.COMPANYS.filter(c=>c.ID!=1):props.COMPANYS,
           ProjDeSelectedIds:[]
        }
        this.input=[]
        this.updateTimer=null
    }

    

    componentDidMount() {
        this.updateTimer=setInterval(()=>{
            this.props.getBACKLOG()
        },10000)
    }

    componentWillUnmount(){
        clearInterval(this.updateTimer)
    }

    renderView(){
        const {COMPANYS,user,PROJECTS,BACKLOG} = this.props
        const {selectedCompanys, ProjDeSelectedIds} = this.state
        let PROJECTS_sorted = PROJECTS.slice(0).sort((a,b)=>a.ID_COMPANY-b.ID_COMPANY)
        
        
        
        return{
            projects:()=>{
                var Company = null
                return PROJECTS_sorted.map(p=>{
                    
                    if(
                        selectedCompanys.findIndex(c=>c.ID==p.ID_COMPANY)==-1 ||
                        user.ROLE!='ADMN' && p.ID_STATUS == 9
                    )
                        return null

                    let isProjSelected = ProjDeSelectedIds.findIndex(prj=>prj==p.ID)
                    
                    var isNewCompany = p.ID_COMPANY != Company
                    Company=p.ID_COMPANY
                    var comp = {}
                    if(isNewCompany){
                        comp = COMPANYS.find(c=>c.ID==p.ID_COMPANY)
                    }
                    let isLongTitle = p.TITLE.length>30
                    
                    var content = (
                                <MenuItem  onClick={()=>{
                                    let tmp = ProjDeSelectedIds.slice(0)
                                    if(isProjSelected==-1) tmp.push(p.ID)
                                    else tmp.splice(isProjSelected,1)

                                    this.setState({ProjDeSelectedIds:tmp})

                                    
                                }}>
                                  
                                    <Chip style={{
                                        color:'white',
                                        backgroundColor:isProjSelected==-1?p.STATUS_COLOR:'rgb(190,190,190)'
                                    }} label={'#'+p.ID} size='small'  className='me-2'/>
                                    <ListItemText><b>{p.TITLE.substr(0,30)}{p.TITLE.length>30?'..':null}</b></ListItemText>
                                    <IconButton  size='small' type='text' onClick={(e)=>{
                                        e.stopPropagation()
                                        this.props.openPopUp(<POP_Project ID_OBJECT={p.ID}/>)
                                    }}> <MoreVertIcon fontSize="inherit" /> </IconButton>
                                </MenuItem>  
                        )

                        if(isLongTitle) 
                                content =  <Tooltip title={'..'+p.TITLE.substr(30)} placement="right">{content}</Tooltip>
                        if(isNewCompany) return([<Divider />,<small className='ps-2'>{comp.TITLE}</small>,content])
                        else return([content])
                    })
                   
            },

            backlog:()=>{
                return BACKLOG.map(task=>{
                    if(
                        selectedCompanys.findIndex(c=>c.ID==task.COMPANY.ID)==-1 ||
                        ProjDeSelectedIds.findIndex(prj=>prj==task.PROJECT.ID)!=-1
                        
                       )
                    return null
                    
                    let haveDEADLINE = task.DATE_DEADLINE!=null
                    let DATE_DEADLINE = null
                    let TIME_DEADLINE = null
                    let DEADLINE_type = null
                    if(haveDEADLINE){
                        DATE_DEADLINE = GF.makeNormalDate(task.DATE_DEADLINE,'dd.mm.yyyy')
                        TIME_DEADLINE = GF.makeNormalDate(task.DATE_DEADLINE,'H:M')

                        if(GF.makeNormalDate(new Date(),'dd.mm.yyyy')==DATE_DEADLINE){
                            DATE_DEADLINE = 'Сегодня'
                            DEADLINE_type='CUR'
                        }else{
                            
                            let daysLag = Math.ceil((new Date().getTime() - new Date(
                                GF.makeNormalDate(task.DATE_DEADLINE,'mm/dd/yyyy H:M')
                            ).getTime()) / (1000 * 3600 * 24))
                            if(daysLag>=-7 && daysLag<=0)
                                DEADLINE_type = 'WEK'
                        }
                        
                    }
                    return ([<Divider />,<MenuItem className='d-block' onClick={()=>{
                            this.props.openPopUp(<POP_Task ID_OBJECT={task.ID}/>)
                        }}>
                            <Chip size='small' className='float-end' label={task.STATUS_TITLE}
                              style={{
                                backgroundColor:task.STATUS_COLOR,
                                color:'white',
                                position:'absolute',
                                top:10,
                                right:15
                            }}/>

                            {task.LAST_HUMAN==null?null:
                             <Chip size='small' className='float-end' avatar={task.LAST_HUMAN.IMAGES_URL==null? <Avatar>{task.LAST_HUMAN.FIO[0]}</Avatar> :
                              <Avatar src={task.LAST_HUMAN.IMAGES_URL[0]} alt={task.LAST_HUMAN.LOGIN[0]}/>
                              }  label={task.LAST_HUMAN.ID_USER==user.ID?'Моя задача':GF.FIO(task.LAST_HUMAN.FIO,2)}
                              style={{
                                position:'absolute',
                                top:40,
                                right:15
                            }}/>
                            }
                            
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            <small>{task.COMPANY.TITLE}</small>
                            <small>{task.PROJECT.TITLE}</small>
                            {!haveDEADLINE?null:
                                <Chip size='small'
                                color={task.ID_STATUS==8?'default':
                                    DEADLINE_type=='CUR'?'error':
                                    DEADLINE_type=='WEK'?'warning':'default'}
                                label={`${DEADLINE_type==null?'':'до'} ${DATE_DEADLINE}${DEADLINE_type!=null?' ':' до '}${TIME_DEADLINE}`}/>
                            }
                        </Breadcrumbs>
                        
                        {task.PROJECT.ID_STATUS!=9?null:
                        <Alert style={{width: 'max-content'}} color='warning'>Проект завершен</Alert>}

                        {task.AUTHOR.ID_USER==1?
                            <p className='mb-3'>{task.TEXT}</p>:[

                            <p className='mb-5'>{task.TEXT}</p>,
                            <Chip size='small' color='success' label={task.AUTHOR.ID_USER==user.ID?'Я автор':task.AUTHOR.FIO}
                              style={{
                                color:'white',
                                position:'absolute',
                                bottom:10,
                                left:15
                            }}/>

                        ]}
                        

                    </MenuItem>])
                })
            }
        }
        
    }

    render (){
        const {COMPANYS,user,BACKLOG} = this.props
        const {selectedCompanys} = this.state
       
        

        return (
           <MainView>
               {user.ROLE!='CLEN'?
                <Autocomplete
                    multiple
                    options={COMPANYS}
                    value={selectedCompanys}
                    onChange={(event, newValue) => {
                        this.setState({selectedCompanys:newValue})
                    }}
                    limitTags={5}
                    getOptionLabel={(option) => option.TITLE}
                    defaultValue={[COMPANYS[0]]}
                    size='small'
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        label="Компании"
                        size='large'
                    />
                    )}
                />:
                    null
                }
               <div className='row'>
                    <div className='col-md-4 pt-3'>
                        {user.ROLE!='ADMN' ?null:
                            <Button variant="text" className='float-end' onClick={()=>
                                this.props.openPopUp(<POP_Project/>)
                            }>Добавить</Button>
                        }
                        <h5 className='mt-1'>Проекты</h5>
                        <Card className='mt-3'>
                            <MenuList dense>
                                {this.renderView().projects()}
                            </MenuList>
                        </Card>
                    </div>
                    <div className='col-md-8 pt-3'>
                        {user.ROLE!='ADMN' && user.ROLE!='CLEN'?null:
                            <Button variant="text" className='float-end' onClick={()=>
                                this.props.openPopUp(<POP_Task />)
                            }>Создать</Button>
                        }
                        <h5 className='mt-1'>Задачи</h5>
                        <Card className='mt-3'>
                            <MenuList >
                                {this.renderView().backlog()}
                            </MenuList>
                        </Card>
                    </div>
                    
               </div>
           </MainView>
        )

            
      
    }
}

const MainView = styled.div`

`

const Card = styled.div`
background:white;
border-radius:5px;
overflow:hidden;
position:relative;
box-shadow:5px 5px 30px rgba(0,0,0,.05);
& span{
    font-size:13px;
    white-space: break-spaces;
}
& li{
    margin-top:0px;
}
& hr{
    margin:0px !important;
}
& ul{
    padding:0px;
}
`





const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getBACKLOG:(arg)=>dispatch(getBACKLOG(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
        COMPANYS:store.page.COMPANYS,
        PROJECTS:store.page.PROJECTS,
        BACKLOG:store.page.BACKLOG,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Index);

