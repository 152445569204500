import React from 'react';
import { connect } from 'react-redux';
import { openPopUp } from '../../actions/PopUpActions'
import { logOut } from '../../actions/UserAct'
import { loadUserData_FromStorage } from '../../actions/UserAct'
import styled from 'styled-components'
import { GlobalFunctions } from '../../GlobalFunctions';
import { withCookies } from 'react-cookie';
import logo from '../../design/logo.svg'
import { up, down, between, only } from 'styled-breakpoints';
import { Avatar, Button, Chip } from '@mui/material';

class TopMenu extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        
    }

    

    componentDidMount() {
        
    }

    exitAk(){
        const { cookies } = this.props
        GlobalFunctions.deleteCookie('login')
        GlobalFunctions.deleteCookie('password')
        this.props.logOut()
        this.props.loadUserData_FromStorage()
    }


    render (){
        const {user,isLogined} = this.props
        return (
            <MasterView>
                
                <Logo src={logo}/>
                {!isLogined?null:[
                <Chip avatar={user.IMAGES_URL==null? <Avatar>{user.LOGIN[0]}</Avatar> :
                              <Avatar src={user.IMAGES_URL[0]} alt={user.LOGIN[0]}/>
                              }  label={user.LOGIN} style={{
                                position:'absolute',
                                bottom:10,
                                right:100
                            }} />,
                <Button variant='text' style={{
                    position:'absolute',
                    bottom:10,
                    right:20
                }} size='small' onClick={()=>this.exitAk()}>Выйти</Button>
                ]}
            </MasterView>
        )
    }
}

const Btn = styled.div`
float: right;
margin-top: 9px;
margin-right: 20px;
`

const MasterView = styled.div`
height:50px;
width:100vw;
background:white;
box-shadow:10px 10px 30px rgba(0,0,0,0.03);
position:fixed;
top:0px;
left:0px;
z-index:10;
`
const Logo = styled.img`
height: 35px;
margin-top: 5px;
margin-left: 5px;
float:left;

${down('md')}{
    height: 26px;
    margin-top: 9px;
    margin-left: 70px;
}
`

const H2 = styled.h3`
font-size: 17px;
float: left;
margin-left: -5px;
color: rgb(116, 100, 63);
font-weight: 200;
transform: rotate(-90deg);
margin-top: 13px;
text-align: center;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        logOut:(arg)=>dispatch(logOut(arg)),
        loadUserData_FromStorage: (arg)=>dispatch(loadUserData_FromStorage(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page,
        user:store.user.data,
        isLogined:store.user.isLogined,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(TopMenu));

