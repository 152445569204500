import React from 'react';
import styled from 'styled-components';
import {GlobalFunctions} from '../../../GlobalFunctions'

class Question extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
        this.key = GlobalFunctions.randomString()
    }

    componentDidMount() {
     
    }

    render (){
        const {text,buttons,contentObject} = this.props
        return (
            <Content key={"Question"+this.key} className="Content content-fluid">
                <p className='text-center'>{text}</p>
                {contentObject}
                <Actions>
                    {buttons}
                </Actions>
            </Content>
                
        )
    }
}

export default Question;

const Content = styled.div`
width:430px;
`
const Actions = styled.div`
width: max-content;
margin: auto;
margin-bottom: -25px;
`


