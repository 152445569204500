

const initialState = {
  isLogined:null,
  data:{}
};
  

  export function userReducer(state = initialState, action) {
    switch (action.type) {

      case 'loadUserData_FromStorage':
        return{
          ...state,
          data:action.payload
        }

      case 'loginIn':
        return{
          ...state,
          isLogined:action.payload.isOk,
          data:action.payload.data
        }

      case 'logOut':
        return{
          ...state,
          isLogined:false,
          data:{}
        }
  
      default:
        return state;
    }
  }