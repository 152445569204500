import React from 'react';
import { connect } from 'react-redux';
import { openPopUp } from '../../actions/PopUpActions'
import styled from 'styled-components'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import MainPage from '../pages/MainPage';
import { withRouter } from "react-router"
import { up, down, between, only } from 'styled-breakpoints';
import DataBase from '../pages/DataBase';
import Tasks from '../pages/Tasks';
import Chats from '../pages/Chats';
import { IconButton } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import Help from '../pages/Help';



const AllMenu = {
    ADMN:[
        {
            title:'Главная',
            url:'/main',
            view:<MainPage/>
        },
        {
            title:'База данных',
            url:'/data',
            view:<DataBase/>
        },
        {
            title:'Задачи',
            url:'/tasks',
            view:<Tasks/>
        },
        {
            title:'Чаты',
            url:'/chats',
            view:<Chats/>
        },
        {
            title:'Помощь',
            url:'/help',
            view:<Help/>
        },
        
    ],
    EMPL:[
        {
            title:'Главная',
            url:'/main',
            view:<MainPage/>
        },
        {
            title:'Задачи',
            url:'/tasks',
            view:<Tasks/>
        },
        {
            title:'Чаты',
            url:'/chats',
            view:<Chats/>
        },
        {
            title:'Помощь',
            url:'/help',
            view:<Help/>
        },
    ],
    CLEN:[
        {
            title:'Главная',
            url:'/main',
            view:<MainPage/>
        },
        {
            title:'Задачи',
            url:'/tasks',
            view:<Tasks/>
        },
        {
            title:'Чаты',
            url:'/chats',
            view:<Chats/>
        },
        
        {
            title:'Помощь',
            url:'/help',
            view:<Help/>
        },
    ]
}

const SimpleRoutes = [
    {
        url:'/chats/:ID_CHAT',
        view:<Chats/>
    },
]

class LeftMenu extends React.Component {
    constructor(props) {
        super()
        this.state = {
           isOpen:false
        }
    }

    

  

    render (){
        const {user} = this.props
        const {isOpen} = this.state
        const menu = AllMenu[user.data.ROLE]
        if(menu==null) return <div/>
        return ([
            <Burger isOpen={isOpen} onClick={()=>this.setState({isOpen:!isOpen})}>
                <div className='b1'/>
                <div className='b2'/>
                <div className='b3'/>
            </Burger>,
            <MasterView isOpen={isOpen}>
                {menu.map(m => (
                    <Link to={m.url} onClick={()=>this.setState({isOpen:false})}>
                        <ListElem isSelected={this.props.location.pathname.indexOf(m.url)==0}>{m.title}</ListElem>
                    </Link>
                ))}
            </MasterView>,

            <ContentView isOpen>
                <Switch>
                    {SimpleRoutes.map(m => 
                        <Route path={m.url}>
                            {m.view}
                        </Route>
                        
                    )}
                    {menu.map(m => 
                        <Route path={m.url}>
                            {m.view}
                        </Route>
                        
                    )}
                    
                    <Route path='/'>
                        <MainPage/>
                    </Route>
                </Switch>
            </ContentView>
        ])
    }
}

const Burger = styled.div`
${up('md')}{
    display:none;
}
height: 32px;
width: 32px;
position: fixed;
top: 7px;
left: 30px;
z-index: 11;
cursor:pointer;
transition:.3s;
padding-top: 7px;

& div{
    transform-origin-x:0%;
    transition:.3s;
    background:rgba(0,0,0,0.8);
    height:3px;
    width:100%;
    margin-bottom:5px;
    border-radius:2px;
}

& div.b2{
    transition:all .3s, transform.3s ease .1s;
}

${props=>props.isOpen?`
    & div.b1{
        transform:scale(0.8,1) translateY(8px) rotate(-34deg);
    }
    & div.b3{
        transform:scale(0.8,1) translateY(-8px) rotate(34deg);
    }
    & div.b2{
        transform:scale(0,1);
    }
    & div:hover{
        margin-bottom:5px;
        background:rgba(0,0,0,1);
    }
    &:hover{
        transform:scale(1);
        padding-top: 7px;
    }
`:`
    &:hover div{
        margin-bottom:8px;
        background:rgba(0,0,0,1);
    }
    &:hover{
        transform:scale(1.1);
        padding-top: 4px;
    
    }
`}

`

const MasterView = styled.div`
width:230px;
height:calc(100vh - 50px);
background:white;
position:fixed;
top:50px;
left:0px;

a{
    color:gray;
    color: rgb(151, 158, 167);
    text-decoration: none;
    font-variant: all-petite-caps;
    font-size: 18px;
    padding: 10px 20px;
    width: 100%;
    display: block;
    cursor: pointer;
    transition: .3s;
    padding-left: 30px;

}
a:hover{
    color:gray;
    background:rgba(0,0,0,0.01);
    
}
z-index:5;

${down('md')}{
    transition:.5s ease-in-out;
    width:60vw;
    ${props=>props.isOpen?`
        transform:translateX(0);
        box-shadow: 10px 0px 30px rgba(0,0,0,0.1);
    `:`
        transform:translateX(-60vw);
        box-shadow: 10px 0px 30px rgba(0,0,0,0);
    `}
    
}
`

const ListElem = styled.div`
transition:.3s;
${props=>props.isSelected?`
color:rgb(33, 37, 41);
`:``}
`

const ContentView = styled.div`
margin-left:230px;
width:calc(100vw - 230px);

height:calc(100vh - 50px);
overflow-y:auto;
margin-top:50px;
overflow-x:hidden;
padding:20px;

${down('md')}{
    margin-left:0px;
    width:100vw;
    padding-bottom:20vh;
    overflow-x:auto;
}
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        user: store.user,
        page:store.page
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LeftMenu));

