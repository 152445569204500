
  const initialState = {
    POPUPS:[],
    
    VARS:[],//статические переменные
    USERS:[],
    DEPTS:[],
    USER_STATUS:[],
    USER_ROLES:[],
    HUMANS:[],
    COMPANYS:[],
    STATUSES:[],
    PROJECTS:[],
    BACKLOG:[],
    CHATS:[],
    CHAT_USERS:[],
  }
  
  export function pageReducer(state = initialState, action) {
    switch (action.type) {


      case 'JUST_SET_VALUE':
        let temp_state={}
        Object.assign(temp_state,state)
        temp_state[action.key] = action.payload
        
        return temp_state

      case 'GET_YC_SERVICES_AND_CATEGORIES':
        
        return {
          ...state,
          YC:{
            ...state.YC,
            ...action.payload
          }
        }
      
      
      default:
        return state;
    }
  }