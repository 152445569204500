
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, Card, CardHeader, IconButton, CardContent, CardActions, LinearProgress, Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp,openPopImgs } from '../../../actions/PopUpActions';
import { getDB_Data,getBACKLOG } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import POP_Project from './POP_Project';
import Question from '../../PopUp/Views/Question';
import POP_SelectEmploy from './POP_SelectEmploy'
import Input_image from '../../components/Input_image';




const form_fields = [
    {
        label:'Что нужно сделать',
        name:'TEXT',
        multiline:true,
        rows:4
    }
]

class POP_Task extends React.Component {
    constructor(props) {
        super()
        this.state = {
            Self:{
                COMPANY:null,
                PROJECT:null,
                STORY:null,
                DATE_DEADLINE:null
            },
            errorTEXT:null
        }
        this.input=[]
        this.filesToUpload=[]
        this.filesToDelete=[]
    }

    

    componentDidMount() {
       
        const {} = this.state
        const {COMPANYS,PROJECTS,ID_OBJECT,BACKLOG,HUMANS,USERS,user} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = BACKLOG.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            var Self = {}
            Object.assign(Self,Self_tmp)


            var keys = ['DATE_CREATE']
            keys.forEach(key=>{
                Self[key] = GF.SQLdateToJsDate(Self[key])
            })
            Object.keys(this.input).forEach(key=>{
                this.input[key].value = Self[key]
            })

            var keys = ['DATE_DEADLINE']
            keys.forEach(key=>{
                Self[key] = GF.SQLdateToJsDate(Self[key])
            })

            Self.STORY=null
            
            this.setState({Self})
            setTimeout(()=>{
                this.getStory()
            },1)
            
            

            
            
        }else{
            this.setState({Self:{
                COMPANY:this.props.COMPANYS[1],
                PROJECT:null,
                STORY:null,
                DATE_DEADLINE:null
            }})
        }
    }

    getStory(){
        const {Self} = this.state
        const {ID_OBJECT,HUMANS,user} = this.props
        var Self_tmp = {}
        Object.assign(Self_tmp,Self)

        GF.API_task({
            operation:'getBACKLOG_STORY',
            ID_TASK:ID_OBJECT
        }).then(data => {
            Self_tmp.STORY = data
            
            Self_tmp.STORY.forEach((s,i)=>{
                Self_tmp.STORY[i].HUMAN = HUMANS.find(h=>h.ID_USER==s.ID_USER) 
                delete(Self_tmp.STORY[i].ID_HUMAN)
            })
            
            this.setState({Self:Self_tmp})
        })
    }

    CloseTask(){
        new Promise((resolve,reject)=>{
            this.props.openPopUp(<Question
            text='Вы уверены что хотите завершить выбранную задачу?'
            buttons={[
                <Button className='ms-1 me-1' onClick={resolve}>Да</Button>,
                <Button className='ms-1 me-1' onClick={reject}>Нет</Button>
            ]}/>)
        }).then(()=>{
            this.props.closePopUp()
            if(this.props.user.ROLE!='EMPL'){
                GF.API_task({
                    operation:'closeTask',
                    ID_TASK:this.props.ID_OBJECT
                }).then(res=>{
                    this.props.getBACKLOG()
                    this.props.closePopUp()
                })
            }else{
                new Promise((resolve,reject)=>{
                    this.props.openPopUp(<Question
                    text='Комментарий к проделаной работе'
                    contentObject={<TextField
                        className='w-100'
                        margin='dense'
                        size='small'
                        multiline
                        rows={5}
                        label='Описание работы'
                        value={this.state.errorTEXT}
                        onChange={(e)=>{
                            this.setState({errorTEXT:e.target.value})
                        }}
                    />}
                    buttons={[
                        <Button className='ms-1 me-1' onClick={resolve}>Продолжить</Button>,
                        <Button className='ms-1 me-1' onClick={reject}>Отмена</Button>
                    ]}/>)
                }).then(()=>{
                    this.props.closePopUp()
                    this.props.closePopUp()
                    GF.API_task({
                        operation:'closeTask',
                        ID_TASK:this.props.ID_OBJECT,
                        TEXT:this.state.errorTEXT
                    }).then(res=>{
                        this.props.getBACKLOG()
                        
                    })
                }).catch(()=>{
                    this.props.closePopUp()
                })
            }
        }).catch(()=>{
            this.props.closePopUp()
        })
        
    }

    uncloceTask(){
        const {Self} = this.state
        new Promise((resolve,reject)=>{
            this.props.openPopUp(<Question
            text='Вы хотите вернуть задачу обратно в работу?'
            buttons={[
                <Button className='ms-1 me-1' onClick={resolve}>Да</Button>,
                <Button className='ms-1 me-1' onClick={reject}>Нет</Button>
            ]}/>)
        }).then(()=>{
            this.props.closePopUp()
            new Promise((resolve,reject)=>{
                this.props.openPopUp(<Question
                text='В чем ошибка исполнителя? Опишите наиболее подробно причину отклонения'
                contentObject={<TextField
                    className='w-100'
                    margin='dense'
                    size='small'
                    multiline
                    rows={5}
                    label='Наставление к задаче'
                    value={this.state.errorTEXT}
                    onChange={(e)=>{
                        this.setState({errorTEXT:e.target.value})
                    }}
                />}
                buttons={[
                    <Button className='ms-1 me-1' onClick={resolve}>Продолжить</Button>,
                    <Button className='ms-1 me-1' onClick={reject}>Отмена</Button>
                ]}/>)
            }).then(()=>{
                
                GF.API_task({
                    operation:'uncloceTask',
                    ID_TASK:this.props.ID_OBJECT,
                    TEXT:this.state.errorTEXT
                }).then(res=>{
                    this.props.getBACKLOG()
                    this.props.closePopUp()
                })
            }).finally(()=>{
                this.props.closePopUp()
            })

           
        }).catch(()=>{
            this.props.closePopUp()
        })
        
    }

    Redirect(){
        const {ID_OBJECT,user} = this.props
        new Promise((resolve,reject)=>{
            this.props.openPopUp(<POP_SelectEmploy callBack={resolve}/>)
        }).then(({USER,TEXT})=>{
            GF.API_task({
                operation:'RedirectTask',
                ID_TASK:ID_OBJECT,
                ID_EMPLOY:USER.ID_EMPLOY,
                TEXT
            }).then(data => {
                this.getStory()
                this.props.getBACKLOG()
                if(user.ROLE=='EMPL')
                    this.props.closePopUp()
            })
        })
        
    }

    getToWork(){
        const {ID_OBJECT} = this.props
        GF.API_task({
            operation:'getToWork',
            ID_TASK:ID_OBJECT
        }).then(data => {
            this.getStory()
            this.props.getBACKLOG()
            setTimeout(()=>
            this.componentDidMount(),300)

            setTimeout(()=>
            this.componentDidMount(),800)
        })
    }


    Save(){
        const {Self} = this.state
        const {ID_OBJECT,user} = this.props
        if(!GF.checkReqvireds([this.input.TITLE])) return
        let data={
           ID_PROJECT:Self.PROJECT.ID,
           TEXT:Self.TEXT
        }
        
        
        let reqv = {
            operation:'createUpdateSome',
            Table:'BACKLOG_TASKS',
            data
        }
        if(user.ROLE=='ADMN' || Self.ID!=null && user.ID == Self.AUTHOR.ID_USER || Self.ID==null && user.ROLE=='CLEN'){
            reqv.data.ID_AUTHOR = user.ID
            reqv.data.DATE_DEADLINE = GF.makeNormalDate(Self.DATE_DEADLINE,'yyyy-mm-dd H:M')
        }


        if(ID_OBJECT!=null)
            reqv.ID = ID_OBJECT

        if(this.props.ID_OBJECT !=null)
            reqv.ID = this.props.ID_OBJECT

        GF.API_task(reqv).then(res=>{
            var promises =[]
            if(this.filesToUpload.length>0)
                promises.push(GF.API_files({
                    operation:'setIMAGE',
                    Table:'BACKLOG_TASKS',
                    ID:res.ID
                },this.filesToUpload.splice(0)))

            if(this.filesToDelete.length>0)
                promises.push(GF.API_task({
                    operation:'deleteIMAGE',
                    ID:this.props.ID_OBJECT,
                    Table:'BACKLOG_TASKS',
                    filesToDelete:JSON.stringify(this.filesToDelete.splice(0))
                }))
            Promise.all(promises).then(()=>{
                this.props.getBACKLOG()
            })

            this.props.getDB_Data('getPROJECTS','PROJECTS')
            this.props.getBACKLOG()
            this.props.closePopUp()
        })
    }

    render (){
        const {COMPANYS,ID_OBJECT,PROJECTS,user} = this.props
        const {Self} = this.state
        let PROJECTS_sorted = Self.COMPANY==null?[]:PROJECTS.filter(p=>p.ID_COMPANY==Self.COMPANY.ID && (
            user.ROLE=='ADMN' || user.ROLE=='CLEN' && p.ID_STATUS!=9
        ))
       
        if(ID_OBJECT!=null && Self.ID==null) return <div/>
    
        return (
            <POP ID_OBJECT={ID_OBJECT}>
                <div className='row'>
                    <div className={ID_OBJECT!=null?'col-md-5':'col-md-12'}>
                        <h4>Задача {ID_OBJECT==null?null:'#'+ID_OBJECT}</h4>
                        {Self.DATE_CREATE==null?null:<p style={{marginTop:-10, marginBottom:0}}>от {GF.makeNormalDate(Self.DATE_CREATE,'dd.mm.yyyy H:M')}</p>}
                        {Self.COMPANY!=null || Self.PROJECT!=null?
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            <small>{Self.COMPANY==null?null:Self.COMPANY.TITLE}</small>
                            <small onClick={()=>
                                this.props.openPopUp(<POP_Project ID_OBJECT={Self.PROJECT.ID}/>)
                            }>{Self.PROJECT==null?null:Self.PROJECT.TITLE}</small>
                        </Breadcrumbs>
                        :null}

                        {user.ROLE!='ADMN' && user.ROLE!='CLEN'?null:[
                            <div className='mt-3'/>,
                            <Autocomplete
                                options={COMPANYS}
                                disabled={Self.ID_STATUS == 8 || user.ROLE!='ADMN'}
                                value={Self.COMPANY==null?null:Self.COMPANY}
                                defaultValue={Self.COMPANY}
                                onChange={(event, newValue) => {
                                    if(newValue==null)
                                        newValue = COMPANYS[0]
                                    this.setState({Self:{...Self,COMPANY:newValue,PROJECT:null}})
                                }}
                                getOptionLabel={(option) => option.TITLE}
                                size='small'
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label="Компания"
                                    size='small'
                                />
                                )}
                            />,
                            <div className='mt-3'/>,
                            <Autocomplete
                                options={PROJECTS_sorted}
                                defaultValue={Self.PROJECT}
                                disabled={Self.ID_STATUS == 8}
                                value={Self.PROJECT==null?null:Self.PROJECT}
                                onChange={(event, newValue) => {
                                    this.setState({Self:{...Self,PROJECT:newValue}})
                                }}
                                getOptionLabel={(option) => option.TITLE}
                                size='small'
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label="Проект"
                                    size='small'
                                />
                                )}
                            />,
            
                            <hr />
                        ]}

                        {form_fields.map(input => 
                            user.ROLE=='ADMN' ||  Self.ID!=null && user.ID == Self.AUTHOR.ID_USER || Self.ID==null && user.ROLE=='CLEN'?
                            <TextField
                                className='w-100 mt-3'
                                margin='dense'
                                size='small'
                                disabled={(user.ROLE!='ADMN' && user.ROLE!='CLEN' ) || Self.ID_STATUS == 8}
                                {...input}
                                value={Self[input.name]}
                                inputRef={ref=>this.input[input.name]=ref}
                                onChange={(e)=>{
                                    let Self_tmp = {}
                                    Object.assign(Self_tmp,Self)
                                    Self_tmp[input.name] = e.target.value
                                    this.setState({Self:Self_tmp})
                                }}
                            />:
                            [
                                <hr/>,
                                <b className='mt-4 d-block'>{input.label}</b>,
                                <p className='mb-4 d-block'>{Self[input.name]}</p>

                            ]
                        )}

                        <div className='mt-3'/>
                        {user.ROLE=='ADMN' ||  Self.ID!=null && user.ID == Self.AUTHOR.ID_USER || Self.ID==null && user.ROLE=='CLEN'?
                        <FormControl fullWidth>
                            <DateTimePicker
                                renderInput={(props) => <TextField  {...props} />}
                                label="Дата и время DEADLINE"
                                value={Self.DATE_DEADLINE}
                                onChange={(e)=>this.setState({Self:{...Self,DATE_DEADLINE:e}})}
                            /></FormControl>: Self.DATE_DEADLINE==null?null:[
                                <Alert severity="error">
                                     <AlertTitle>DEADLINE</AlertTitle>
                                     {GF.makeNormalDate(Self.DATE_DEADLINE,'dd.mm.yyyy H:M')}
                                </Alert>
                        ]}
                        
                        {user.ROLE=='ADMN' ||  Self.ID!=null && user.ID == Self.AUTHOR.ID_USER || Self.ID==null && user.ROLE=='CLEN'?
                            <div className='mb-2' style={{height:140, marginTop:15}}>
                                <Input_image value={Self.IMAGES_URL} multiple={true} onChange={(filesToUpload,filesToDelete)=>{
                                    
                                    this.filesToUpload=[...this.filesToUpload,...filesToUpload]
                                    this.filesToDelete=[...this.filesToDelete,...filesToDelete]
                                    
                                }}/>
                            </div>:
                                Self.IMAGES_URL==null || Self.IMAGES_URL.length==0?null:[
                                    <hr/>,
                                    <p>Приложенные файлы</p>,
                                
                                <ScrollViewSmall className='hiddenScroll'>
                                    {Self.IMAGES_URL==null?null:Self.IMAGES_URL.map(img=>
                                        <Img src={`${global.URL_host}img/${img}`}
                                        onClick={(e)=>this.props.openPopImgs({
                                            selectedImg:0,
                                            aImgs:[e.target.src]
                                        })} />
                                    )}
                                </ScrollViewSmall>
                            ]
                        }
                    </div>
                    {ID_OBJECT==null?null:
                    <div className='col-md-7'>
                        <h5>История</h5>
                        <ScrollView className='hiddenScroll' length={Self.STORY==null?1:Self.STORY.length}>
                            {Self.STORY==null?
                                <LinearProgress/>:
                            Self.STORY.length==0?
                                <small>Действий не обнаружено</small>
                            :
                            Self.STORY.map(story=>{
                                return(
                                    <Card sx={{mb:2}}>
                                        {story.IS_AUTOCREATE==1?
                                        <small className='mt-2 ps-3 pe-3'>{GF.makeNormalDate(story.DATE_CREATE,'dd.mm.yyyy H:M')}</small>:
                                        <CardHeader
                                            avatar={story.HUMAN.IMAGES_URL==null? <Avatar sx={{ bgcolor: 'red' }}>{story.HUMAN.FIO[0]}</Avatar> :
                                            <Avatar src={story.HUMAN.IMAGES_URL[0]} alt={story.HUMAN.FIO[0]}/>
                                            } 
                                            title={GF.FIO(story.HUMAN.FIO,2)}
                                            subheader={GF.makeNormalDate(story.DATE_CREATE,'dd.mm.yyyy H:M')}
                                        />}
                                        <p className='ps-3 pe-3'>{story.TEXT}</p>
                                        </Card>
                                )
                            })}
                        </ScrollView>
                    </div>
                    }
                </div>
                

                <div className='mt-3'/>
                {Self.ID_STATUS == 8 || user.ROLE=='CLEN' &&  Self.ID!=null && Self.AUTHOR.ID_USER!=user.ID ||
                user.ROLE=='EMPL' && Self.LAST_HUMAN.ID_USER!=user.ID?null:<hr/>}
                
                {(user.ROLE!='ADMN' &&  Self.ID!=null && user.ID != Self.AUTHOR.ID_USER ) || Self.ID_STATUS == 8 || Self.ID_STATUS==7 || ID_OBJECT==null?null:
                    <Button className='float-end ms-1 me-1' onClick={()=>this.Redirect()}>Назначить</Button>
                }
                {(
                    !(user.ROLE=='ADMN') && 
                    !(user.ROLE=='CLEN' && Self.ID!=null && user.ID == Self.AUTHOR.ID_USER) &&
                    !(user.ROLE=='EMPL' && Self.LAST_HUMAN.ID_USER==user.ID && Self.ID_STATUS!=7)
                ) || this.props.ID_OBJECT==null || Self.ID_STATUS == 8 || Self.ID_STATUS==10  ? null:
                    <Button className='float-end ms-1 me-1' color='success' onClick={()=>this.CloseTask()}>Завершить</Button>
                }

                {ID_OBJECT==null || (user.ROLE!='ADMN' && user.ROLE!='CLEN') || Self.ID_STATUS!= 7?null:
                    <Button color='error' className='float-end ms-1 me-1' onClick={()=>this.uncloceTask()}>ЗАДАча не решена</Button>
                }

                

                {(user.ROLE!='ADMN' &&  Self.ID!=null && user.ID != Self.AUTHOR.ID_USER ) || Self.ID_STATUS == 8?null:
                    <Button className='float-left' onClick={()=>this.Save()}>Сохранить изменения</Button>
                }

                {Self.ID_STATUS != 10 || user.ROLE!='EMPL' || user.ROLE=='EMPL' && Self.LAST_HUMAN.ID_USER!=user.ID ?null:
                    <Button className='float-end' color='success' onClick={()=>this.getToWork()}>Взять в работу</Button>
                }

                {Self.ID_STATUS == 10 || user.ROLE!='EMPL' || Self.ID_STATUS == 8 || user.ROLE=='EMPL' && Self.LAST_HUMAN.ID_USER!=user.ID || Self.ID_STATUS==7?null:
                    <Button className='float-end me-1' onClick={()=>this.Redirect()}>передать задачу</Button>
                }
                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:${props=>props.ID_OBJECT!=null?'900px':'400px'};
`

const ScrollView = styled.div`
max-height:60vh;
height:${props=>props.length*150}px;
transition:1s;
overflow:auto;
padding:20px;
margin-left:-20px;
margin-right:-20px;
`
const ScrollViewSmall = styled.div`
max-height:30vh;
overflow:auto;
margin-right: -20px;
margin-bottom: -17px;
`



const Img = styled.img`
width: calc(50% - 20px);
margin-right:20px;
height: 100px;
border-radius: 10px;
transition: .3s;
margin-bottom:20px;
object-fit: cover;
&:nth-child(2n+1){
    margin-right:20px;
}

`



const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),

        getBACKLOG:(arg)=>dispatch(getBACKLOG(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
        openPopImgs:(arg)=>dispatch(openPopImgs(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        PROJECTS:store.page.PROJECTS,
        COMPANYS:store.page.COMPANYS,
        BACKLOG:store.page.BACKLOG,
        HUMANS:store.page.HUMANS,
        user:store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_Task);

