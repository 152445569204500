
import { AccessTime, AccessTimeFilled, LibraryAddCheck, PeopleAlt, TaskAlt, Work } from '@mui/icons-material';
import { Button, Card, CardActions, CardHeader, IconButton ,CardContent} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import {down, up} from 'styled-breakpoints'
import {withRouter} from 'react-router-dom'




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
    
    }


    render (){
        const {PROJECTS,user,COMPANYS,BACKLOG,STATUSES} = this.props
       
        

        if(user.ROLE=='ADMN')
        return (
           <MainView>
               <div className='row'>
                    
                    <div className='col-md-4 mb-2'>
                        <Card>
                            <CardHeader
                                avatar={<PeopleAlt/>}
                                title="Клиенты"
                                subheader="Заведенные в систему"
                            />
                                <CardContent className='InfoBlock'>
                                    <InfoBlock count={1}>
                                        <h1>{COMPANYS.length-1}</h1>
                                        <small>Все</small>
                                    </InfoBlock>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={()=>this.props.history.push('/data/clients')}>Подробнее</Button>
                                </CardActions>
                        </Card>
                    </div>
                    <div className='col-md-4 mb-2'>
                        
                        <Card>
                            <CardHeader
                                avatar={<Work/>}
                                title="Проекты"
                                subheader="Статистика проектов"
                            />
                                <CardContent className='InfoBlock'>
                                    <InfoBlock count={3}>
                                        <h1>{PROJECTS.length}</h1>
                                        <small>Все</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==3).COLOR
                                        }}>{PROJECTS.filter(p=>p.ID_STATUS==3).length}</h1>
                                        <small>В работе</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==9).COLOR
                                        }}>{PROJECTS.filter(p=>p.ID_STATUS==9).length}</h1>
                                        <small>Закрытые</small>
                                    </InfoBlock>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={()=>this.props.history.push('/data/projects')}>Подробнее</Button>
                                </CardActions>
                        </Card>
                    </div>
                    <div className='col-md-4 mb-2'>
                        <Card>
                            <CardHeader
                                avatar={<LibraryAddCheck/>}
                                title="Задачи"
                                subheader="Список всех задач "
                            />
                                <CardContent className='InfoBlock'>
                                    <InfoBlock count={3}>
                                        <h1>{BACKLOG.length}</h1>
                                        <small>Все</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==6).COLOR
                                        }}>{BACKLOG.filter(p=>p.ID_STATUS==6 || p.ID_STATUS==7 || p.ID_STATUS==10).length}</h1>
                                        <small>В работе</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==8).COLOR
                                        }}>{BACKLOG.filter(p=>p.ID_STATUS==8).length}</h1>
                                        <small>Закрытые</small>
                                    </InfoBlock>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={()=>this.props.history.push('/tasks')}>Подробнее</Button>
                                </CardActions>
                        </Card>
                    </div>
                    
               </div>
           </MainView>
        )






        if(user.ROLE=='EMPL')
        return (
           <MainView>
               <div className='row'>
                    <div className='col-md-4 mb-2'>
                       
                        <Card>
                            <CardHeader
                                avatar={
                                <TaskAlt/>
                                }
                                title="Мои задачи"
                                subheader="Задачи, назначенные мне"
                            />
                                <CardContent className='InfoBlock'>
                                    <InfoBlock count={1}>
                                        <h1>{BACKLOG.length}</h1>
                                        <small>Все</small>
                                    </InfoBlock>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={()=>this.props.history.push('/tasks')}>Подробнее</Button>
                                </CardActions>
                            </Card>
                            
                    </div>
                    
               </div>
           </MainView>
        )

        if(user.ROLE=='CLEN')
        return (
           <MainView>
               <div className='row'>
                <div className='col-md-4 mb-2'>
                        <Card>
                            <CardHeader
                                avatar={<Work/>}
                                title="Проекты"
                                subheader="Проекты, моей компании"
                            />
                                <CardContent className='InfoBlock'>
                                    <InfoBlock count={3}>
                                        <h1>{PROJECTS.length}</h1>
                                        <small>Все</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==3).COLOR
                                        }}>{PROJECTS.filter(p=>p.ID_STATUS==3).length}</h1>
                                        <small>В работе</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==9).COLOR
                                        }}>{PROJECTS.filter(p=>p.ID_STATUS==9).length}</h1>
                                        <small>Закрытые</small>
                                    </InfoBlock>
                                </CardContent>
                            </Card>   
                    </div>
                <div className='col-md-4 mb-2'>
                        <Card>
                            <CardHeader
                                avatar={
                                <TaskAlt/>
                                }
                                title="Мои задачи"
                                subheader="Задачи, моей компании"
                            />
                                <CardContent className='InfoBlock'>
                                    <InfoBlock count={3}>
                                        <h1>{BACKLOG.length}</h1>
                                        <small>Все</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==6).COLOR
                                        }}>{BACKLOG.filter(p=>p.ID_STATUS==6 || p.ID_STATUS==7 || p.ID_STATUS==10).length}</h1>
                                        <small>В работе</small>
                                    </InfoBlock>
                                    <InfoBlock count={3}>
                                        <h1 style={{
                                            color:STATUSES.find(s=>s.ID==8).COLOR
                                        }}>{BACKLOG.filter(p=>p.ID_STATUS==8).length}</h1>
                                        <small>Закрытые</small>
                                    </InfoBlock>
                                </CardContent>
                            </Card>   
                    </div>
                </div>
           </MainView>
        )

            
      
    }
}

const MainView = styled.div`
& .InfoBlock{
    display: flex;
    justify-content: center;
}
`

const InfoBlock = styled.div`
z-index: 0;
width:calc(100% / ${props=>props.count});
text-align: center;
border-left: 1px solid gray;
&:first-child{
    border-left: 1px solid transparent;
}
& small{
    margin-top: -10px;
    display: block;
}

${down('lg')}{
    & h1{
        font-size:20px;
    }
    & small{
        font-size:10px;
    }
}

`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
        POPUPS:store.page.POPUPS,
        PROJECTS:store.page.PROJECTS,
        COMPANYS:store.page.COMPANYS,
        BACKLOG:store.page.BACKLOG,
        STATUSES:store.page.STATUSES,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

