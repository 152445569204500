
import { Check, Delete } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid, ListItem, IconButton, ListItemButton } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import { getDB_Data,getBACKLOG,getDB_Data_lazy,getUSERS } from '../../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'
import POP_EmplDept from './POP_EmplDept';
import InputMask from 'react-input-mask';





const form_fields = {
    HUMANS:[
        {
            label:'ФИО',
            name:'FIO',
        },
        {
            label:'E-mail',
            name:'EMAIL',
        },
        {
            label:'Номер телефона',
            name:'PHONE',
        },
        {
            label:'Метод комуникации',
            name:'COMMUNICATION_METHOD',
        },
        {
            label:'Описание',
            name:'DESCR',
            multiline:true,
            rows:4
        }
    ],
    EMPL:[],
    CLEN:[]
}

class POP_User extends React.Component {
    constructor(props) {
        super()
        this.state = {
            Self:{
                ROLE:props.ROLE,
                ID_STATUS:2,
                DPTS:[]
            }
        }
        this.input=[]
        this.can=true
    }

    

    componentDidMount() {
        const {} = this.state
        const {COMPANYS,DEPTS,ID_OBJECT,USERS} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = USERS.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            var Self = {}
            Object.assign(Self,Self_tmp)
            Object.keys(this.input).forEach(key=>{
                this.input[key].value = Self[key]
                
            })
            this.setState({Self})
        }
    }

    Delete(){
        const {COMPANYS,DEPTS,ID_OBJECT,user} = this.props

        GF.API_task({
            operation:'deleteSome',
            Table:'COMPANYS',
            ID:ID_OBJECT
        }).then(res=>{
            switch(user.ROLE){
                case'ADMN':
                    this.props.getDB_Data_lazy('COMPANYS')
                break;
                case'EMPL':
                    this.props.getDB_Data('getCOMPANYS','COMPANYS')
                break;
            }
            
            this.props.closePopUp()
        })
    }


    async Save(){


        const {Self} = this.state
        const {COMPANYS,user} = this.props
        if(!GF.checkReqvireds([this.input.FIO,this.input.EMAIL]))return

        if(!this.can) return
        this.can=false
        
        let data = {}
        Object.keys(this.input).forEach(key=>{
            try{
                let val = this.input[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })
        
        

        let reqv = {
            operation:'createUpdateSome',
            Table:'HUMANS',
            keyColumn:'ID_USER',
            data
        }
        reqv.data.PHONE= reqv.data.PHONE.replace(/[ \t(\t,)\t,+\t,_,-]/g,'')
        if(this.props.ID_OBJECT !=null)
            reqv.ID = this.props.ID_OBJECT


        if(Self.ID==null){
            reqv.operation='createUser'
            reqv.keyColumn=null
            reqv.ROLE = Self.ROLE
            await new Promise((resolve,reject)=>{
                GF.API_task(reqv).then(res=>{
                    this.setState({Self:{...Self,
                        ID:res.ID_USER,
                        ID_EMPLOY:res.ID_EMPLOYE,
                        ID_CLIENT:res.ID_CLIENT
                    }})
                    this.props.getUSERS()
                    this.props.closePopUp()
                    
                    setTimeout(()=>resolve(),2)
                }).catch(()=>reject())
            })
            
        }else{
            await GF.API_task(reqv).then(res=>{
                this.props.getUSERS()
                this.props.closePopUp()
            })
        }

        this.can=true

        if(Self.ROLE=='CLEN' && Self.COMPANY!=null && Self.COMPANY.ID!=null){
            GF.API_task({
                operation:'updateCLEN',
                ID_USER:this.state.Self.ID,
                ID_COMPANY:this.state.Self.COMPANY.ID,
                ID_CLIENT:this.state.Self.ID_CLIENT,
                PROJECTS: this.state.Self.PROJECTS.map(p=>p.ID)
            }).then(res=>{
                this.props.getUSERS()
            })
        }
        
    }

    DeleteGrade(dept){
        const {Self} = this.state

        GF.API_task({
            operation:'deleteEmplDeptGrade',
            ID_DEPT:dept.ID,
            ID_EMPLOY:Self.ID_EMPLOY,
        }).then(res=>{
            this.props.getUSERS()
            setTimeout(()=>this.componentDidMount(),300)
            setTimeout(()=>this.props.getDB_Data('getPROJECTS','PROJECTS'),500)
        })
    }

    OpenChatWith(){
        const {COMPANYS,user,ID_OBJECT,USER_STATUS,PROJECTS} = this.props
        GF.API_task({
            operation:'getOrCreatePrivateChat',
            ID_USER:ID_OBJECT
        }).then(data => {
            window.location.replace('/chats/'+data.ID_CHAT) 
      })
    }

    render (){
        const {COMPANYS,user,ID_OBJECT,USER_STATUS,PROJECTS} = this.props
        const {Self} = this.state
        let PROJECTS_sorted = Self.COMPANY==null?[]:PROJECTS.filter(p=>p.ID_COMPANY==Self.COMPANY.ID)
       
        if(ID_OBJECT!=null && Self.ID==null) return <div/>
     
        return (
            <POP>
                
                <div className='row mb-2'>
                    <div className='col-6'>
                        <h4>{Self.ROLE_TITLE==null?'Пользователь':Self.ROLE_TITLE}</h4>
                        <FormControl fullWidth sx={{mt:2}}>
                            <InputLabel id="ID_STATUS-label">Статус</InputLabel>
                            <Select
                                disabled={user.ROLE!='ADMN' || Self.ID==1}
                                value={Self.ID_STATUS}
                                labelId="ID_STATUS-label"
                                id="ID_STATUS"
                                variant='outlined'
                                label='Статус'
                                size='small'
                                onChange={(e,value)=>this.setState({Self:{...Self,ID_STATUS:value.props.value}})}
                                >
                                {USER_STATUS.map(s=>
                                    s.ID==1?null:<MenuItem value={s.ID}>{s.TITLE}</MenuItem>
                                )}
                            </Select>
                            {form_fields['HUMANS'].map(input => 
                                input.name=='PHONE'?
                                <InputMask mask="+7 (999) 999-99-99" value={Self[input.name]} onChange={e=>{
                                    let tmp = Object.assign({},Self)
                                    tmp[input.name] = e.target.value.replace(/[ \t(\t,)\t,+\t,_,-]/g,'')
                                    this.setState({Self:tmp})
                                }} 
                                disabled={user.ROLE!='ADMN'}
                                {...input}>
                                    {(inputProps) =>  
                                        <TextField
                                        {...inputProps}
                                        className='w-100 mt-3'
                                        margin='dense'
                                        inputRef={ref=>this.input[input.name]=ref}
                                        size='small'
                                        
                                        
                                    />}
                                </InputMask>:
                                    <TextField
                                        className='w-100 mt-3'
                                        margin='dense'
                                        disabled={user.ROLE!='ADMN'}
                                        size='small'
                                        onChange={e=>{
                                            let tmp = Object.assign({},Self)
                                            tmp[input.name] = e.target.value
                                            this.setState({Self:tmp})
                                        }}
                                        inputRef={ref=>this.input[input.name]=ref}
                                        value={Self[input.name]}
                                        {...input}
                                    />
                            )}

                            {form_fields[Self.ROLE].map(input => 
                                    <TextField
                                        className='w-100 mt-3'
                                        margin='dense'
                                        value={Self[input.name]}
                                        disabled={user.ROLE!='ADMN'}
                                        size='small'
                                        inputRef={ref=>this.input[input.name]=ref}
                                        onChange={e=>{
                                            let tmp = Object.assign({},Self)
                                            tmp[input.name] = e.target.value
                                            
                                            this.setState({Self:tmp})
                                        }}
                                        {...input}
                                    />
                            )}
                            </FormControl>
                    </div>
                    <div className='col-6'  style={{paddingTop:52}}>
                        <FormControl fullWidth>
                        {Self.ROLE=='CLEN'?<div>
                            <InputLabel id="ID_COMPANY-label">Компания</InputLabel>
                            <Select
                                disabled={user.ROLE!='ADMN'}
                                value={Self.COMPANY}
                                labelId="ID_COMPANY-label"
                                id='ID_COMPANY'
                                className='w-100'
                                variant='outlined'
                                label='Компания'
                                size='small'
                                onChange={(e,value)=>this.setState({Self:{...Self,COMPANY:value.props.value,PROJECTS:[]}})}
                                >
                                {COMPANYS.map(c=>
                                    <MenuItem value={c}>{c.TITLE}</MenuItem>
                                )}
                            </Select>

                            <div className='mt-3'/>
                            <Autocomplete
                                    multiple
                                    options={PROJECTS_sorted}
                                    defaultValue={Self.PROJECTS}
                                    disabled={user.ROLE!='ADMN'}
                                    value={Self.PROJECTS==null?[]:Self.PROJECTS}
                                    onChange={(event, newValue) => {
                                        this.setState({Self:{...Self,PROJECTS:newValue}})
                                    }}
                                    className='w-100'
                                    getOptionLabel={(option) => option.TITLE}
                                    size='small'
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label="Проекты"
                                        size='small'
                                    />
                            )}/>
                        </div>: //список должностей исполнителя
                            <div>
                                <Button disabled={Self.ID==null} onClick={()=>
                                this.props.openPopUp(<POP_EmplDept USER={Self} callBack={()=>this.componentDidMount()}/>)
                            }>Добавить должность</Button>
                            
                                <ScrollView className='hiddenScroll'>
                                {Self.ID!=null?null:<small>Для добавления должностей - сохраните изменения</small>}
                                    {Self.DPTS.map(dept=>
                                        <ListItem
                                            className='item'
                                            secondaryAction={
                                            <IconButton edge="end" size='small' className='deleter'
                                            onClick={()=>this.DeleteGrade(dept)}>
                                                <Delete />
                                            </IconButton>
                                            }disablePadding>
                                            <div  className='item'  onClick={()=>
                                            this.props.openPopUp(<POP_EmplDept OBJECT={dept} USER={Self} callBack={()=>this.componentDidMount()}/>)}>
                                                <Chip style={{
                                                backgroundColor:dept.COLOR,
                                                color:'white'
                                                }} className='me-1 mb-1' label={dept.TITLE+' | '+dept.GRADE} size="small" />
                                            </div>
                                        </ListItem>
                                    )}
                                </ScrollView>
                            </div>
                        }

                        </FormControl>
                    </div>
                </div>
               

                {this.props.ID_OBJECT==null || user.ROLE!='ADMN' || Self.ID==1?null:
                    <Button className='float me-2' color='error' onClick={()=>this.Delete()}>удалить</Button>
                }
                <Button className='float-end' onClick={()=>this.Save()}>Сохранить</Button>
                {this.props.ID_OBJECT==null || user.ROLE!='ADMN'?null:
                    <Button className='float-end' color='secondary' onClick={()=>this.OpenChatWith()}>Перейти в чат</Button>
                }

            </POP>
        )

            
      
    }
}

const POP = styled.div`
width:800px;
`
const ScrollView = styled.div`
max-height:60vh;
overflow:auto;
padding-top:20px;
& .item{
    cursor:pointer;
}
& .item .deleter{
    opacity:0;
    transition:.2s;
}
& .item:hover .deleter{
    opacity:1;
}
`




const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getBACKLOG:(arg)=>dispatch(getBACKLOG(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        PROJECTS:store.page.PROJECTS,
        COMPANYS:store.page.COMPANYS,
        USERS:store.page.USERS,
        user:store.user.data,
        USER_STATUS:store.page.USER_STATUS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_User);

