
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import { getDB_Data,getBACKLOG,getDB_Data_lazy,getUSERS } from '../../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'



class POP_EmplDept extends React.Component {
    constructor(props) {
        super()
        this.state = {
            Self:{},
            selectedDept:null
        }
        this.input=[]
    }

    

    componentDidMount() {
        const {OBJECT,DEPTS} = this.props

        if(OBJECT!=null){
            var Self = Object.assign({},OBJECT)
            var selectedDept = DEPTS.find(d=>d.ID==Self.ID)
            
            this.setState({Self,selectedDept})
        }
    }

    Delete(){
        const {COMPANYS,DEPTS,OBJECT,USER} = this.props

        GF.API_task({
            operation:'deleteEmplDeptGrade',
            ID_DEPT:OBJECT.ID,
            ID_EMPLOY:USER.ID_EMPLOY
        }).then(res=>{
            this.props.getUSERS()
            setTimeout(()=>this.props.callBack(),300)
            setTimeout(()=>this.props.getDB_Data('getPROJECTS','PROJECTS'),500)
            this.props.closePopUp()
        })
    }


    Save(){
        const {Self,selectedDept} = this.state
        const {OBJECT,USER} = this.props
        //if(!GF.checkReqvireds(this.input)) return
       
        
        let reqv = {
            operation:'createUpdateGrade',
            GRADE_TITLE:Self.GRADE,
            ID_SELECTED_DEPT:selectedDept.ID,
            ID_EMPLOY:USER.ID_EMPLOY,
            ID_USER:USER.ID
        }
        if(OBJECT !=null)
            reqv.ID_DEPT = OBJECT.ID

        GF.API_task(reqv).then(res=>{
            
            this.props.getUSERS()
            setTimeout(()=>this.props.callBack(),300)
            setTimeout(()=>this.props.getDB_Data('getPROJECTS','PROJECTS'),500)
            this.props.closePopUp()
        })
    }

    render (){
        const {COMPANYS,user,OBJECT,USER_STATUS,PROJECTS,DEPTS,USER} = this.props
        const {Self,selectedDept} = this.state
        if(OBJECT!=null && Self.ID==null) return <div/>
        return (
            <POP>
                <h4>Должность</h4>

                <div className='mt-4'/>
                <Autocomplete
                    options={DEPTS.filter(d=>
                        USER.DPTS.findIndex(udp=>
                            udp.ID==d.ID && (
                                OBJECT!=null &&
                                udp.ID!=OBJECT.ID ||  OBJECT==null
                            )
                        )==-1
                    )}
                    defaultValue={selectedDept}
                    disabled={user.ROLE!='ADMN'}
                    value={selectedDept}
                    onChange={(event, newValue) => {
                        this.setState({selectedDept:newValue})
                    }}
                    className='w-100'
                    getOptionLabel={(option) => option.TITLE}
                    size='small'
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='outlined'
                        label="Отдел"
                        size='small'
                    />
                    
            )}/>
                <TextField
                    className='w-100 mt-3 mb-3'
                    disabled={user.ROLE!='ADMN'}
                    size='small'
                    label='Долность'
                    value={Self.GRADE}
                    onChange={(e)=>this.setState({Self:{...Self,GRADE:e.target.value}})}
                    ref={ref=>this.input.GRADE=ref}
                />
            
                            
            

                {this.props.OBJECT==null || user.ROLE!='ADMN' || Self.ID==1?null:
                    <Button className='float me-2' color='error' onClick={()=>this.Delete()}>удалить</Button>
                }
                <Button className='float-end' onClick={()=>this.Save()}>Сохранить</Button>
                

            </POP>
        )

            
      
    }
}

const POP = styled.div`

width:300px;
`





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getBACKLOG:(arg)=>dispatch(getBACKLOG(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        PROJECTS:store.page.PROJECTS,
        DEPTS:store.page.DEPTS,
        user:store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_EmplDept);

