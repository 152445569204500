
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getBACKLOG } from '../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import Question from '../../PopUp/Views/Question';




const form_fields = [
    {
        label:'Название',
        name:'TITLE',
    },
    {
        label:'Описание',
        name:'DESCR',
        multiline:true,
        rows:4
    }
]

class POP_Project extends React.Component {
    constructor(props) {
        super()
        this.state = {
            Self:{
                ID_COMPANY:1,
                DATE_START:null,
                DATE_DEADLINE:null
            }
        }
        this.input=[]
    }

    

    componentDidMount() {
        const {} = this.state
        const {COMPANYS,PROJECTS,ID_OBJECT} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = PROJECTS.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            var Self = {}
            Object.assign(Self,Self_tmp)
            var keys = ['DATE_START','DATE_END','DATE_CREATE','DATE_DEADLINE']
            keys.forEach(key=>{
                Self[key] = GF.SQLdateToJsDate(Self[key])
            })
            Object.keys(this.input).forEach(key=>{
                this.input[key].value = Self[key]
                
            })
            this.setState({Self})
        }
    }


    Save(){
        const {Self} = this.state
        if(!GF.checkReqvireds([this.input.TITLE])) return
        let data = {}
        Object.keys(this.input).forEach(key=>{
            try{
                let val = this.input[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })
        data={
            ...data,
            ID_COMPANY:Self.ID_COMPANY,
            DATE_START:GF.makeNormalDate(Self.DATE_START,'yyyy-mm-dd H:M'),
            DATE_DEADLINE:GF.makeNormalDate(Self.DATE_DEADLINE,'yyyy-mm-dd H:M'),
        }
        
        let reqv = {
            operation:'createUpdateSome',
            Table:'PROJECTS',
            data
        }
        if(this.props.ID_OBJECT !=null)
            reqv.ID = this.props.ID_OBJECT

        GF.API_task(reqv).then(res=>{
            this.props.getDB_Data('getPROJECTS','PROJECTS')
            this.props.closePopUp()
        })
    }

    CloseTask(){
        new Promise((resolve,reject)=>{
            this.props.openPopUp(<Question
            text='Вы уверены что хотите завершить проект?'
            buttons={[
                <Button onClick={resolve}>Да</Button>,
                <Button onClick={reject}>Нет</Button>
            ]}/>)
        }).then(()=>{
            GF.API_task({
                operation:'closeProject',
                ID_PROJECT:this.props.ID_OBJECT
            }).then(res=>{
                this.props.getBACKLOG()
                this.props.getDB_Data('getPROJECTS','PROJECTS')
                this.props.closePopUp()
            })
        }).finally(()=>{
            this.props.closePopUp()
        })
        
    }

    render (){
        const {COMPANYS,user} = this.props
        const {Self} = this.state
       
    
        return (
            <POP>
                <h4>Проект</h4>

                <FormControl fullWidth sx={{mt:2}}>
                <Chip style={{
                    color:'white',
                    backgroundColor:Self.STATUS_COLOR,
                    width: 'max-content',
                    position: 'absolute',
                    right: 0,
                    top: 5
                    
                }}  size='small' label={Self.STATUS_TITLE}/>
                    <InputLabel id="ID_COMPANY">Компания</InputLabel>
                    <Select
                        disabled={user.ROLE!='ADMN' || Self.ID_STATUS==9}
                        className='w-50'
                        value={Self.ID_COMPANY}
                        labelId="ID_COMPANY"
                        variant='outlined'
                        label='Компания'
                        size='small'
                        
                        onChange={(e,value)=>this.setState({Self:{...Self,ID_COMPANY:value.props.value}})}
                        >
                        {COMPANYS.map(c=>
                            <MenuItem value={c.ID}>{c.TITLE}</MenuItem>
                        )}
                    </Select>
                
                    {form_fields.map(input => 
                        user.ROLE=='ADMN'?
                        <TextField
                            className='w-100 mt-3'
                            margin='dense'
                            disabled={user.ROLE!='ADMN' || Self.ID_STATUS==9}
                            size='small'
                            {...input}
                            inputRef={ref=>this.input[input.name]=ref}
                        />:[
                            <b>{input.label}</b>,
                            <p>{Self[input.name]}</p>
                        ]
                    )}

                    
                        <div className='mt-3'/>
                        <DateTimePicker
                            disabled={user.ROLE!='ADMN' || Self.ID_STATUS==9}
                            renderInput={(props) => <TextField {...props} />}
                            label="Дата и время начала"
                            value={Self.DATE_START}
                            onChange={(e)=>this.setState({Self:{...Self,DATE_START:e}})}
                        />
                        <div className='mt-3'/>
                        <DateTimePicker
                            disabled={user.ROLE!='ADMN' || Self.ID_STATUS==9}
                            renderInput={(props) => <TextField  {...props} />}
                            label="Дата и время DEADLINE"
                            value={Self.DATE_DEADLINE}
                            onChange={(e)=>this.setState({Self:{...Self,DATE_DEADLINE:e}})}
                        />

                        {Self.ID_STATUS!=9?null:[
                            <div className='mt-3'/>,
                            <DateTimePicker
                                disabled={user.ROLE!='ADMN' || Self.ID_STATUS==9}
                                renderInput={(props) => <TextField  {...props} />}
                                label="Дата и время завершения"
                                value={Self.DATE_END}
                                onChange={(e)=>this.setState({Self:{...Self,DATE_DEADLINE:e}})}
                            />
                        ]}
                    

                </FormControl>


                <div className='mt-3'/>

                {this.props.ID_OBJECT==null || Self.ID_STATUS == 9 || user.ROLE!='ADMN' ? null:
                    <Button className='float me-2' color='error' onClick={()=>this.CloseTask()}>Завершить</Button>
                }

                
                {Self.ID_STATUS == 9 || user.ROLE!='ADMN' ?null:
                    <Button className='float-end' onClick={()=>this.Save()}>Сохранить</Button>
                }

                



                
            </POP>
        )

            
      
    }
}

const POP = styled.div`
min-height:100px;
width:500px;
`





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getBACKLOG:(arg)=>dispatch(getBACKLOG(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        PROJECTS:store.page.PROJECTS,
        COMPANYS:store.page.COMPANYS,
        user:store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_Project);

