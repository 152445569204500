import {GlobalFunctions as GF} from "../GlobalFunctions"
import {store} from '../store/configureStore'



export function JSONparse(data,key){
  data.forEach((d,index)=>{
    try{
      data[index][key] = JSON.parse(d[key])
    }catch(e){ data[index][key] = [] } 
  })
  return data
}

export function getDB_Data(operation,tableName,JSONparseKey){
  return (dispatch) => {
    GF.API_task({
      operation
    }).then(data=>{
      
      if(Array.isArray(JSONparseKey)){
        
        JSONparseKey.forEach(key=>{
          if(key!=null)
            JSONparse(data,key)
        })
        
      }
      else
        if(JSONparseKey!=null)
          JSONparse(data,JSONparseKey)
      
      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload:data
      })
    })
    
  }
}

export function getDB_Data_lazy(tableName,JSONparseKey){
  return (dispatch) => {
    GF.API_task({
      operation:'getDataFromTable',
      Table:tableName
    }).then(data=>{
      
      if(Array.isArray(JSONparseKey)){
        
        JSONparseKey.forEach(key=>{
          if(key!=null)
            JSONparse(data,key)
        })
        
      }
      else
        if(JSONparseKey!=null)
          JSONparse(data,JSONparseKey)
      
      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload:data
      })
    })
    
  }
}




export function getVARS(arg) {
  return (dispatch) => {
      
    GF.API_task({
        operation:'getVARS'
    }).then(data => {
      let obj = {}
      data.forEach(d=>{
        if(!isNaN(d.VALUE))
          d.VALUE=d.VALUE*1
        obj[d.NAME] = {}
        obj[d.NAME].value = d.VALUE
        obj[d.NAME].category = d.CATEGORY
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:obj,
        key:'VARS'
      })
    })
    
  }
}








export function getUSERS(){
  return (dispatch) => {
    
    GF.API_task({
      operation:'getUSERS'
    }).then(async data => {

      JSONparse(data,'IMAGES_URL')

      let DEPTS = [] //ожидание получения данных
      while(DEPTS.length<=0){
        DEPTS = store.getState().page.DEPTS
        await GF.WaitingTimer(10)
      }

      let COMPANYS = [] //ожидание получения данных
      while(COMPANYS.length<=0){
        COMPANYS = store.getState().page.COMPANYS
        await GF.WaitingTimer(10)
      }

      let PROJECTS = [] //ожидание получения данных
      while(PROJECTS.length<=0){
        PROJECTS = store.getState().page.PROJECTS
        if(store.getState().user.data.ROLE!='CLEN')
          await GF.WaitingTimer(10)
      }

      

      data.forEach((d,i)=>{
        if(d.DPTS_JSON!=null && d.DPTS_JSON!='' && d.DPTS_JSON!=' ')
          try{
            data[i].DPTS_JSON = JSON.parse(d.DPTS_JSON)
          }catch(e){data[i].DPTS_JSON = []}
        else
          data[i].DPTS_JSON = []
          
          
        if(d.PROJECTS_JSON!=null && d.PROJECTS_JSON!='' && d.PROJECTS_JSON!=' ')
          try{
            data[i].PROJECTS_JSON = JSON.parse(d.PROJECTS_JSON)
          }catch(e){data[i].PROJECTS_JSON = []}
        else
          data[i].PROJECTS_JSON = []

        data[i].PROJECTS = data[i].PROJECTS_JSON.slice(0)
        
        delete(data[i].PROJECTS_JSON)
        data[i].PROJECTS.forEach((pr,pi)=>{
          data[i].PROJECTS[pi]=PROJECTS.find(proj=>proj.ID == pr)
          if(data[i].PROJECTS[pi]==null)
            data[i].PROJECTS.splice(pi,1)
          
        })
        
          
        if(d.GRADE_JSON!=null && d.GRADE_JSON!='' && d.GRADE_JSON!=' ')
          try{
            data[i].GRADE_JSON = JSON.parse(d.GRADE_JSON)
          }catch(e){data[i].GRADE_JSON = []}
        else 
          data[i].GRADE_JSON = []
          

        if(data[i].GRADE_JSON[0]=='')
          data[i].GRADE_JSON = []

        data[i].DPTS_JSON.forEach((dp,di)=>{
          
          data[i].DPTS_JSON[di] =  Object.assign({},DEPTS.find(dps=>dps.ID == dp))
          data[i].DPTS_JSON[di].GRADE =  d.GRADE_JSON[di]+''
        })
        data[i].DPTS = data[i].DPTS_JSON
        delete(data[i].DPTS_JSON)
        delete(data[i].GRADE_JSON)

        if(d.COMPANY!=null){
          data[i].COMPANY = COMPANYS.find(c=>c.ID==data[i].COMPANY)
        }
      })

      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'USERS'
      })

    })
  }
}

export function getBACKLOG(){
  return (dispatch) => {
    
    GF.API_task({
      operation:'getBACKLOG'
    }).then(async data => {

      JSONparse(data,'IMAGES_URL')

     

        data = data.sort((a,b)=>a.ID_PROJECT-b.ID_PROJECT)
  
        let COMPANYS = [] //ожидание получения данных
        while(COMPANYS.length<=0){
          COMPANYS = store.getState().page.COMPANYS
          await GF.WaitingTimer(10)
        } 

        let PROJECTS = [] //ожидание получения данных
        while(PROJECTS.length<=0){
          PROJECTS = store.getState().page.PROJECTS
          await GF.WaitingTimer(10)
        } 

        let HUMANS = [] //ожидание получения данных
        while(HUMANS.length<=0){
          HUMANS = store.getState().page.HUMANS
          await GF.WaitingTimer(10)
        } 
        
        data.forEach((d,i)=>{
          data[i].COMPANY = COMPANYS.find(c=>c.ID==data[i].ID_COMPANY)
          delete(data[i].ID_COMPANY)

          data[i].PROJECT = PROJECTS.find(c=>c.ID==data[i].ID_PROJECT)
          delete(data[i].ID_PROJECT)

          data[i].LAST_HUMAN = HUMANS.find(h=>h.ID_USER==data[i].ID_LAST_USER)

          data[i].AUTHOR = HUMANS.find(h=>h.ID_USER==data[i].ID_AUTHOR)
          delete(data[i].ID_AUTHOR)
          
        })

        

        

        
        
        
  
        dispatch({
          type: 'JUST_SET_VALUE',
          payload:data,
          key:'BACKLOG'
        })

      })


    
  }
}

export function getCHATS(){
  return (dispatch) => {
    
    GF.API_task({
      operation:'getCHATS'
    }).then(async data => {

      let USERS = [] //ожидание получения данных
      while(USERS.length<=0){
        USERS = store.getState().page.USERS
        await GF.WaitingTimer(10)
      }

      let CHAT_USERS = [] //ожидание получения данных
      while(CHAT_USERS.length<=0){
        CHAT_USERS = store.getState().page.CHAT_USERS
        await GF.WaitingTimer(10)
      }

      let COMPANYS = [] //ожидание получения данных
      while(COMPANYS.length<=0){
        COMPANYS = store.getState().page.COMPANYS
        await GF.WaitingTimer(10)
      }

      

      data.forEach((chat,i)=>{
        if(chat.ID_COMPANY!=null)
          data[i].COMPANY = Object.assign({},COMPANYS.find(c=>c.ID==chat.ID_COMPANY))
        else data[i].COMPANY = null
        delete(data[i].ID_COMPANY)

        data[i].DIALOG_USERS = []

        CHAT_USERS.forEach(chu=>{
          if(chu.ID_CHAT == chat.ID){
            let tmp_user = Object.assign({},USERS.find(us=>us.ID == chu.ID_USER))
            if(tmp_user!=null){
              tmp_user.isChatAuthor = false
              data[i].DIALOG_USERS.push(tmp_user)
            }
          }
        })

        let tmp_user_author = Object.assign({},USERS.find(us=>us.ID == chat.ID_AUTHOR))
        if(tmp_user_author!=null){
          tmp_user_author.isChatAuthor = true
          data[i].DIALOG_USERS.push(tmp_user_author)
        }

      })

      
        dispatch({
          type: 'JUST_SET_VALUE',
          payload:data,
          key:'CHATS'
        })

    })


    
  }
} 