
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'
import { Label } from '../../../components/moduls/Input';
import Table from '../../../components/Table';
import { Button, ButtonGroup, Chip, TextField } from '@mui/material';
import POP_Dept from './POP_Dept';




const Rows = [

  {
      title:'Название',
      key:'TITLE'
  },
  {
      title:'Описание',
      key:'DESCR',
  },
  {
    title:'Цвет',
    key:'COLOR',
    width:'100px'
  },
  
]

class Depts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }


    PreDelete(){
      let promises = []
      let somethingDoing = false

        Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing=true
            this.checkBoxs[key].checked=false
            promises.push(this.Delete(key))
            delete(this.checkBoxs[key])
          }
        })

        if(!somethingDoing) return

        Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
        })
      
    }

    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'USERS',
          ID
        }
        
        GF.API_task(data).then(res=>{
         
         
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }

    changeBlock(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'changeBlockUser',
          ID
        }
        
        GF.API_task(data).then(res=>{
          
          setTimeout(()=>resolve(),1)
        }).catch(()=>reject()) 
      })
    }

    Block(){
      let somethingDoing = false
      let promises = []
      Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing = true
            this.checkBoxs[key].checked=false
            promises.push(this.changeBlock(key))
          }
      })
      
      if(!somethingDoing) return

      Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Значение обновлено', { appearance: 'success',autoDismiss:true });
        })
    }

    


    render (){
        const {DEPTS} = this.props
        const {filter,Data} = this.state
        return(
          <View>
            <div className='mt-4'>
              <TextField
                className='me-2'
                label='Поиск'
                variant='outlined'
                size="small"
                onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
              />

              <Button className='me-2 align-bottom' variant='text' onClick={()=>{
                Object.keys(this.checkBoxs).forEach((key,index)=>{
                  this.checkBoxs[key].checked = true
                })
              }}>Выбрать все</Button>

              <Button className='me-2 align-bottom' variant='text' onClick={()=>{
                Object.keys(this.checkBoxs).forEach((key,index)=>{
                  this.checkBoxs[key].checked = false
                })
              }}>Cнять выделение</Button>

              
            

              <Button className='me-2 align-bottom' variant='text' onClick={()=>{
                this.props.openPopUp(<POP_Dept/>)
              }}>Создать</Button>

           

            </div>
              
          
              <Table
                    className='mt-1'
                    rows={Rows}
                    canCheck={true}
                    checkBoxs={this.checkBoxs}
                    data={DEPTS.filter(a=>filter==null || a.TITLE.toUpperCase().indexOf(filter)!=-1  )}
                    itemOnClick={({data,index})=>{
                        this.props.openPopUp(<POP_Dept ID_OBJECT={data.ID}/>)
                    }}
                    rowOnClick={({data,index})=>{
                        debugger
                    }}
                    renderItem={({data,index})=>
                      Rows.map(r=>{
                        switch(r.key){
                          case 'COLOR':
                            return(<td><Chip style={{
                              backgroundColor:data.COLOR,
                              color:'white'
                            }} className='me-1 mb-1' label={data.COLOR} size="small" /></td>)
                          default: return(<td>{data[r.key]}</td>)
                        }
                        
                      })  
                    }/>

           
          </View>
        )
    }
}
const View = styled.div`
height:100%;
`

const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        DEPTS: store.page.DEPTS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Depts);

