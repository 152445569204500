
import { AttachFile, Attachment, Check } from '@mui/icons-material';
import { Autocomplete, Avatar,Badge, Breadcrumbs, Button, Chip, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, TextField, Tooltip, Typography , IconButton} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {JSONparse,getCHATS} from '../../../actions/PageActions'
import { openPopUp,openPopImgs } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import { Label } from '../../components/moduls/Input';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import {withRouter} from 'react-router-dom'
import POP_SelectEmploy from '../Tasks/POP_SelectEmploy';




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
            selectedCompanys:props.COMPANYS,
          
            MSGs:[],
            lastChatsMsgsId:[],
            newMSGtext:''
        }
        
        this.input=[]
        this.autoApdateTimer = null
        this.lastUpdateTime = null
        this.previosChatId = null
        this.nowUpdatingChats = false
        this.ChatBody=null

        this.nowDateDay = GF.makeNormalDate(new Date(),'dd.mm.yyyy')

        this.AllChatUpdateTimer = null
    }

    getMSGs(variant){
        try{
            if(this.nowUpdatingChats && variant!='noTimer') return
            this.nowUpdatingChats=true

            

            const {MSGs} = this.state
            const {} = this.props
            
            if(this.props.match.params.ID_CHAT==null){
                this.setState({MSGs:[]})
                this.lastUpdateTime=null
                this.previosChatId=null
                return
            }

            
            var updatingChatId = this.props.match.params.ID_CHAT
            GF.API_task({
                operation:'getChatMSGs',
                ID_CHAT:this.props.match.params.ID_CHAT,
                lastTimeUpdate:this.lastUpdateTime==null?null:GF.makeNormalDate(this.lastUpdateTime,'yyyy-mm-dd H:M:S')
            }).then(data => {
                try{
                    this.nowUpdatingChats=false
                    if(updatingChatId!=this.props.match.params.ID_CHAT){
                        this.setState({MSGs:[]}) 
                        this.lastUpdateTime=null
                        this.previosChatId=null
                        return
                    }
                    this.previosChatId=updatingChatId
                    this.lastUpdateTime = new Date() //Мы получаем все сообщения диалога только при его открытии, а потом мы подгружаем только новые
                    console.warn('lastUpdateTime='+this.lastUpdateTime)
                    let msgs_tmp = Object.assign({},MSGs)
                    msgs_tmp = MSGs.concat(data)
                    JSONparse(data,'HUMAN_IMAGES_URL')
                    JSONparse(data,'IMAGES_URL')
                    
                    this.setState({MSGs:msgs_tmp})

                    if(data.length>0)
                    this.ChatBody.scrollTop=this.ChatBody.scrollHeight
                    if(variant!='noTimer')
                        this.autoApdateTimer = setTimeout(()=>this.getMSGs(),5000)
                }catch(e){}
            })
        }catch(e){}
    }
    componentDidMount(){
        this.AllChatUpdateTimer = setInterval(()=>this.props.getCHATS(),10000)
        if(this.props.match.params.ID_CHAT!=null)
            this.getMSGs()
    }

    componentWillUnmount(){
        clearTimeout(this.autoApdateTimer)
        clearInterval(this.AllChatUpdateTimer)
    }

    renderView(){
        const {COMPANYS,CHATS,user} = this.props
        const {selectedCompanys,MSGs} = this.state

        return{
            MSGs:()=>{
                let lastUserId = null
                return MSGs.map(msg=>{
                    let msgDate = GF.makeNormalDate(msg.DATE_CREATE,'dd.mm.yyyy')
                    let msgTime = GF.makeNormalDate(msg.DATE_CREATE,'H:M')
                    let justTime = false
                    if(msgDate==this.nowDateDay) justTime = true

                    let isAnotherUser = false
                    let isIam = msg.ID_USER==user.ID
                    if(lastUserId!=msg.ID_USER){
                        isAnotherUser=true
                        lastUserId=msg.ID_USER
                    }
                    let text=msg.TEXT.split(`\n`)
                            
                            let result_text = []
                            text.forEach((t,i)=>{
                                result_text.push(t)
                                if(i>0)
                                result_text.push(<br/>)
                            })

                    return[
                        !isAnotherUser?null:
                            <Chip color={isIam?'primary':'default'} avatar={msg.HUMAN_IMAGES_URL==null? <Avatar>{msg.FIO[0]}</Avatar> :
                                <Avatar src={msg.HUMAN_IMAGES_URL[0]} alt={msg.FIO}/>
                                }  label={msg.FIO}/>
                        ,
                        <Tooltip title={(justTime?'Сегодня':msgDate)+' '+msgTime} placement="left-end">
                            <MSG isIam={isIam} style={{
                                maxWidth:msg.IMAGES_URL==null || msg.IMAGES_URL.length==0?null:310,
                                marginTop:msg.IMAGES_URL==null || msg.IMAGES_URL.length==0?null:15,
                            }}>
                                <p>{text}</p>
                                {msg.IMAGES_URL==null || msg.IMAGES_URL.length==0?null:[
                                    <MSG_img onClick={(e)=>this.props.openPopImgs({
                                        selectedImg:0,
                                        aImgs:[e.target.src],
                                      })} src={`${global.URL_host}img/${msg.IMAGES_URL[0]}`}/>,
                                    msg.IMAGES_URL.map((img,i)=>
                                        i==0?null:
                                        <MSG_img onClick={(e)=>this.props.openPopImgs({
                                            selectedImg:0,
                                            aImgs:[e.target.src],
                                          })} index={i} count={msg.IMAGES_URL.length-1} src={`${global.URL_host}img/${img}`}/>
                                    )
                                    ]
                                }
                            </MSG>
                        </Tooltip>
                    ]
                })
            }
        }
    }
    sendFiles(target){
        
        this.sendMSG({
            canEmpty:true,
            dontGetNewMSGs:true
        }).then(msg=>{
            
            GF.API_files({
                operation:'setIMAGE',
                Table:'CHAT_MSG',
                ID:msg.ID
            },target.files).then(()=>{
                setTimeout(()=>this.getMSGs('noTimer'),300)
            })
        })
        
    }

    sendMSG(type={canEmpty:false,dontGetNewMSGs:false}){
        return new Promise((resolve,reject)=>{
            
            const {selectedCompanys,MSGs,newMSGtext} = this.state
            if(!type.canEmpty)
                if(newMSGtext==null || newMSGtext.length==0){
                    reject('empty msg')
                    return
                }
            let TEXT = newMSGtext+''
            this.setState({newMSGtext:''})
            
            GF.API_task({
                operation:'sendMSG',
                ID_CHAT:this.props.match.params.ID_CHAT,
                TEXT
            }).then(data => {
                if(!type.dontGetNewMSGs)
                    this.getMSGs('noTimer')
                setTimeout(()=>resolve(data),100)
            }).catch(e=>{
                
                reject(e)})
        })
    }

    OpenChatWith(ID_USER){
        const {COMPANYS,user,ID_OBJECT,USER_STATUS,PROJECTS} = this.props
        GF.API_task({
            operation:'getOrCreatePrivateChat',
            ID_USER
        }).then(data => {
            window.location.replace('/chats/'+data.ID_CHAT) 
      })
    }
    

    render (){
        const {COMPANYS,CHATS,user} = this.props
        const {selectedCompanys,MSGs,newMSGtext} = this.state
       
        

        return (
           <MainView>
                <ChatsView  className=''>
                    <Autocomplete
                        multiple
                        options={COMPANYS}
                        value={selectedCompanys}
                        onChange={(event, newValue) => {
                            this.setState({selectedCompanys:newValue})
                        }}
                        limitTags={5}
                        getOptionLabel={(option) => option.TITLE}
                        defaultValue={COMPANYS}
                        size='small'
                        className='mb-2'
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            label="Представители компаний"
                            size='small'
                        />
                        )}
                    />


                    {user.ROLE!='ADMN' && user.ROLE!='EMPL'?null:
                        <Button className='w-100' onClick={()=>{
                            new Promise((resolve,reject)=>{
                                this.props.openPopUp(<POP_SelectEmploy allowSelfSelect={false} showTextField={false} callBack={resolve}/>)
                            }).then(({USER,TEXT})=>{
                                this.OpenChatWith(USER.ID)
                            })
                            
                        }}>Новый чат</Button>
                    }


                    {CHATS.map(chat=>{
                        if(selectedCompanys.findIndex(s=>s.ID==chat.COMPANY.ID)==-1) return null
                        let title = null

                        if(chat.TITLE !=null)
                            title = chat.TITLE 
                        else if(chat.IS_PRIVATE==1){
                            let ferstNotIam = Object.assign({},chat.DIALOG_USERS.filter(cdu=>cdu.ID!=user.ID))
                            if(ferstNotIam==null || ferstNotIam.length==0)
                                title = `Группа ${chat.ID}`
                            else
                            title =  GF.FIO(ferstNotIam[0].FIO)
                        }
                       
                        return (<div className='p-0'>
                            <Chat noOneIsSelected={this.props.match.params.ID_CHAT==null} isSelected={this.props.match.params.ID_CHAT==chat.ID} onClick={()=>{
                                clearTimeout(this.autoApdateTimer)
                                this.nowUpdatingChats=false
                                this.setState({MSGs:[],newMSGtext:''})
                                this.props.history.push('/chats/'+chat.ID)
                                this.lastUpdateTime=null
                                this.previosChatId=null
                                
                                setTimeout(()=>this.getMSGs(),1)
                            }}>
                                <Avatar alt={title} src="/static/images/avatar/1.jpg" />
                                <p>{title}</p>
                                <small>{chat.COMPANY==null?null:chat.COMPANY.TITLE}</small>
                                {/*<NewMSG/>*/}
                                {user.ROLE!='ADMN'?null:
                                    <IconButton color="inherit" size='small'onClick={(e)=>{
                                            e.stopPropagation()
                                            
                                        }}> <MoreVertIcon fontSize="inherit" /> </IconButton>
                                }
                            </Chat>
                        </div>)
                    }  )}

                    
                </ChatsView>
                



                <ChatBody ref={ref=>this.ChatBody=ref}>
                    {this.renderView().MSGs()}
                </ChatBody>

                <ChatSender isChatActive={this.props.match.params.ID_CHAT!=null}>
                    <TextField label="Ваше сообщение" variant="filled" size='large' multiline rows={6} 
                    value={newMSGtext} onKeyUp={(e)=>{
                        if(e.keyCode==13){
                            this.setState({newMSGtext:e.target.value.substr(0,e.target.value.length-1)})
                            setTimeout(()=>{
                                this.sendMSG()
                            },1)
                            
                        }
                    }} onChange={(e)=>{
                        
                        this.setState({newMSGtext:e.target.value+''})
                    }}
                    />
                    <div className='chat-actions'>
                        <IconButton color='primary' onClick={(e)=>{
                            e.stopPropagation()
                            this.sendMSG()
                        }}> <SendIcon/>
                        </IconButton>
                        <br/>
                        <IconButton onClick={(e)=>{
                            e.stopPropagation()
                            this.input.files.click()
                        }}> <AttachFile/>
                        </IconButton>
                        <input ref={ref=>this.input.files=ref} multiple={true} hidden={true} accept="image/png,image/jpeg,image/jpg" type="file"  onChange={(e)=>this.sendFiles(e.target)}/>
                    </div>
                    
                </ChatSender>
           </MainView>
        )

            
      
    }
}


const MSG_img = styled.img`
max-width: 290px;
max-height:300px;
min-height:100px;
border-radius: 3px;
margin-top: 10px;
object-fit:cover;
display:block;
${props=>props.count>0?`
    min-width:${300/4 - 10}px;
    min-height:${300/4 - 10}px;
    width: ${300/props.count - 10}px;
    height: ${300/props.count - 10}px;
    display:inline-block;
    ${props.index % 4==0 ||  props.index == props.count  ?`
    
    `:`
    margin-right: 10px;
    `}
    
    
`:`

`}
`


const ChatSender = styled.div`
position:absolute;

left:0px;
width:calc(100% - 300px);
background:rgba(255,255,255,0.8);
backdrop-filter:blur(10px);
box-shadow:0px -10px 30px rgba(0,0,0,.05);
padding: 20px;
height: 200px;
transition:.5s ease .3s;

& .MuiFormControl-root,
& .MuiFilledInput-root{
    height:100%;
    width: calc(100% - 30px);
}
& .chat-actions{
    position:absolute;
    right:20px;
    top:20px;
}
${props=>props.isChatActive?`
bottom:0px;
`:`
bottom:-200px;
`}
`

const MSG = styled.div`
background:white;
padding: 10px;
border-radius: 5px;
width: max-content;
max-width: 70%;
margin-bottom: -8px;
${props=>props.isIam?`
background:rgb(135, 172, 210);
color:white;
`:null}
& p{
    margin:0;
    line-height: 16px;
    font-size:14px;
}
}
`

const ChatBody = styled.div`
width:100%;
height:calc(100vh - 50px);
top:0px;
position:absolute;
overflow:auto;
padding:20px;
padding-bottom:220px;

padding-top:calc(100vh - 300px);
& .MuiChip-root{
    margin-top: 28px;
    margin-bottom: 5px;
}
`

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  }));
  
const MainView = styled.div`
position:relative;
height:calc(100% + 40px);
width:calc(100% + 40px);
overflow:hidden;
padding:0px;
margin:-20px;
`

const NewMSG = styled.div`
background: red;
width: 8px;
height: 8px;
position: absolute;
border-radius: 200px;
top: 11px;
left: calc(50% + 11px);

`


const ChatsView = styled.div`
width:300px;
transition:1s;
margin: 0px;
position:absolute;
top:0px;
right:0px;
z-index:10;
padding: 10px;
padding-left:20px;
padding-right:20px;
box-shadow:0px 10px 30px rgba(0,0,0,0.05);
background:rgba(255,255,255,0.8);
height:calc(100vh - 50px);
overflow:auto;

`
const Chat = styled.div`
position:relative;
width:100%;
margin-right:10px;
margin-bottom:10px;
overflow:hidden;
border-radius:5px;
padding:10px;
cursor:pointer;
transition:.2s;
& .MuiAvatar-root{
    position:absolute;
}

${props=>props.isSelected?`
background:#1976d2 !important;
& p{
    color:white !important;
}
& small{
    color:white !important;
}
& .MuiAvatar-root{
    background: white !important;
    color: #1976d2 !important;
}
& button{
    opacity:1;
}
`:`
opacity:0.7;
& button{
    opacity:0;
}
`}

${props=>props.noOneIsSelected?`opacity:1;`:null}
&:hover{
    background:white;
    opacity:1;
}
& .MuiAvatar-root{
    margin:auto;
    transition:.2s;
    background: #1976d2;
}
& p{
    transition:.2s;
    margin:0;
    font-size:13px;
    width:100%;
    display:block;
    text-align:left;
    padding-left:50px;
}
& small{
    margin:0;
    transition:.2s;
    display:block;
    width:100%;
    text-align:left;
    padding-left:50px;
}
& button{
    transition:.3s;
    position: absolute;
    top: 3px;
    right: 3px; 
}
`





const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getCHATS:(arg)=>dispatch(getCHATS(arg)),
        openPopImgs:(arg)=>dispatch(openPopImgs(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
        
        user: store.user.data,
        COMPANYS:store.page.COMPANYS,
        PROJECTS:store.page.PROJECTS,
        CHATS:store.page.CHATS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index));

