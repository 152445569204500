import { encode } from "base-64"
import axios from 'axios'

class Gloabl_ {
    setCookie(name,value,days=365) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
      }

      deleteCookie(name) {
        this.setCookie(name, "", {
          'max-age': -1
        })
      }

      makeCoast(x) {
        try{
        var parts = x.toString().split(".")
        if(parts.length==2){
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          return parts.join(".")
        }else
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }catch(e){return x}
      }

      maskPhone(elem, masked = '+7 (___) ___-__-__') {
	
        function mask(event) {
          const keyCode = event.keyCode;
          const template = masked,
            def = template.replace(/\D/g, ""),
            val = this.value.replace(/\D/g, "");
          console.log(template);
          let i = 0,
            newValue = template.replace(/[_\d]/g, function (a) {
              return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
            });
          i = newValue.indexOf("_");
          if (i !== -1) {
            newValue = newValue.slice(0, i);
          }
          let reg = template.substr(0, this.value.length).replace(/_+/g,
            function (a) {
              return "\\d{1," + a.length + "}";
            }).replace(/[+()]/g, "\\$&");
          reg = new RegExp("^" + reg + "$");
          if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) {
            this.value = newValue;
          }
          if (event.type === "blur" && this.value.length < 5) {
            this.value = "";
          }
      
        }
      
        elem.addEventListener("input", mask);
        elem.addEventListener("focus", mask);
        elem.addEventListener("blur", mask);
        
      }

      getClearPhone(phone){
        try{
          return phone.replaceAll(' ','').replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll('+','')
        }catch(ex){
          return phone
        }
      }

      getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
      }
    
    
      randomString() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
      }
    
    
      phoneFormatter(phone){
        if(phone==null) return null
        if(phone.length<11) return "+"+phone
        let phArr = phone.split('')
        return `+${phArr[0]} (${phArr[1]}${phArr[2]}${phArr[3]}) ${phArr[4]}${phArr[5]}${phArr[6]}-${phArr[7]}${phArr[8]}-${phArr[9]}${phArr[10]}`
      }
    
      
      okonchanie(count,t1,t2,t3){
        if(count%10 >1 && count%10 <5 && !(count>=10 && count<=15)){
          return t2;
        }else if((count%10 >=5 && count%10 <=9)||count%10 ==0 || (count>=10 && count<=15)){
          return t3;
        }else{
          return t1;
        }
      }
    
    
      makeNormalDate(Date_,format){
        if(Date_==null) return null
          try{
          var y = Date_.getFullYear();
          }catch(e){
        var tmpD=Date_;
        Date_=Date_.replace(/ /g,"T")
            Date_=new Date(Date_);
          
            if(Date_.getFullYear()>0){}else
              Date_=new Date("1998-11-26T"+tmpD);
        
            
              var y = Date_.getFullYear();
        
          }
        
          var m = Date_.getMonth()+1; if(m<10) m="0"+m;
          var d = Date_.getDate();   if(d<10) d="0"+d;
        
          var H = Date_.getHours();   if(H<10) H="0"+H;
          var M = Date_.getMinutes();   if(M<10) M="0"+M;
          var S = Date_.getSeconds();   if(S<10) S="0"+S;
          var I = Date_.getMilliseconds();  
        
          format= format.replace("yyyy",y);
          format= format.replace("dd",d);
          format= format.replace("mm",m);
        
          format= format.replace("H",H);
          format= format.replace("M",M);
          format= format.replace("S",S);
          format= format.replace("I",I);
        
          return format;
          
      }

      WaitingTimer(ms){
        return new Promise(resolve=>{
          setTimeout(()=>{
            resolve()
          },ms)
        })
      }
    
      isStringDateTime(stringDate){
        if(stringDate==null) return null
        return stringDate.indexOf(' ')!=-1
      }
      stringDateTime_to_normalDateTime(stringDate){
        if(stringDate==null) return null
        let arr = stringDate.split(' ')
        let hms = arr[1].split(':')
        return new Date(new Date(arr[0]).setHours(hms[0],hms[1],hms[2]))
        
      }

      SQLdateToJsDate(date_string){
        try{
        if(date_string==null || date_string=='') return null
        
        return new Date(date_string.replace(/-/g, '/'))
        }catch(e){
          return null
        }
      }
      dateTimeZoneFix(date){
        if(date==null) return null
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
        return date
      }
    
      checkReqvireds(arrayReqv,ignorArray=[]){
        var isOk=true;
        try{
        Object.keys(arrayReqv).forEach(key => {
            try{
          var itIgnor=false;
          for(var i=0;i<ignorArray.length;i++){
            if(ignorArray[i]==key){
              itIgnor=true;
              break;
            }
          }
          
            if(!itIgnor&&((arrayReqv[key].value==null  || arrayReqv[key].value.length==0 || arrayReqv[key].value===-1 || arrayReqv[key].value==-100) && key!='file')){
             
                arrayReqv[key].classList.add("bg-danger");
                
              
                isOk=false;
            }else{
                
                arrayReqv[key].classList.remove("bg-danger");
            }
          }catch(e){}
        });
      }catch(e){}
        return isOk;
      }
    
      OTHER_API_task(URL,GET_ARGS_ARRAY){
        return this.API_task(GET_ARGS_ARRAY,URL+"?")
      }
    
      API_task(GET_ARGS_ARRAY){
    
        console.log('')
        console.group(`Запрос к серверу ${GET_ARGS_ARRAY.operation!=null? GET_ARGS_ARRAY.operation : ''}`)
        console.warn(GET_ARGS_ARRAY)
        console.groupEnd()
        
    
        var headers={
          'Content-Type': 'application/json',
        }
    
        let
        login = this.getCookie('login'),
        password = this.getCookie('password')

        var token = login + ":" + password
        var hash = encode(token)

        var Authorization = login!=null && password!=null? 
          `Basic ${hash}` : null
        if(Authorization != null)
          headers.Authorization = Authorization
        
          
    
        return new Promise((resolve,reject)=>{
          axios({
            method:'post',
            url:`${global.URL_API}`,
            headers: headers,
            data:JSON.stringify(GET_ARGS_ARRAY)
          }).then( response => {
            console.log('')
            console.group(`Ответ получен ${GET_ARGS_ARRAY.operation!=null? GET_ARGS_ARRAY.operation : ''}`)
            console.log(GET_ARGS_ARRAY)
            console.log(response.data)
            
            
            
            if(response.data.error != null){
              
              console.error('Ошибка в запросе на сервер')
              console.groupEnd()
              reject(response.data.error)
            }else{
              console.groupEnd()
              if(response.data.result!=null)
                resolve(response.data.result)
              else{
               
                console.error('Ошибка на серверe');
                reject(response.data)
              }
            }
            
    
            
          }).catch(error=>{
            
            console.error('Ошибка в запросе на сервер' +error)
            console.log('')
            console.log('')
            reject(error)
          })
        })
        
      }
    
      API_files(GET_ARGS_ARRAY,files){
        console.warn("API_files");
        console.log(GET_ARGS_ARRAY)
        console.log(files)
    
        return new Promise((resolve,reject)=>{
          var API_URL = global.serverURL
          
          var formData = new FormData();
          for(let i=0;i<files.length;i++){
            formData.append('file'+i, files[i]);
          }
        
    
          var ARGS="";
              
          Object.keys(GET_ARGS_ARRAY).forEach((key) => {
              ARGS+="&";
            var itJSON=false;
            try{
              if(JSON.stringify(GET_ARGS_ARRAY[key])[0]=="{"){
                itJSON=true;
                }
            }catch(e){}
            if(itJSON)
              GET_ARGS_ARRAY[key]=JSON.stringify(GET_ARGS_ARRAY[key]);
            ARGS=ARGS+key+"="+GET_ARGS_ARRAY[key]
          });
          
          console.log("")
          console.log(API_URL+ARGS)
          console.log(GET_ARGS_ARRAY)
    
          
    
          var headers={
            'Content-Type': 'application/json',
          }
      
          let
          login = this.getCookie('login'),
          password = this.getCookie('password')
  
          var token = login + ":" + password
          var hash = encode(token)
  
          var Authorization = login!=null && password!=null? 
            `Basic ${hash}` : null
          if(Authorization != null)
            headers.Authorization = Authorization
          
         
          axios({
            method:'post',
            url:`${global.URL_API}${ARGS}`,
            headers,
            data:formData
          }).then( response => {
            
            console.log("");
            console.log("result ")
            console.log(response.data);
          
            if(response.data.error!=undefined){
              console.log("");
              console.error("Ошибка ");
              console.error(response.data.error);
              reject()
            }else{
              resolve(response.data.result)
            }
          }).catch(error=>{
            debugger
            reject(error)
          })
        })
      }

      priceWithSpaces(x) {
        if(x==null) return ' - '
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
          
      FIO(fio,count=1){
        let result=fio
        try{
          let array = fio.split(' ');
          switch(array.length){
            case 1: result = array[0]; break
            case 2:
              if(count==0)
                  result = array[0] 
              if(count==1)
                  result = array[0] + ' ' + array[1][0] + '. ' 
              if(count==2)
                  result = array[0] + ' ' + array[1] 
                break
            default:
              case 3:
                if(count==0)
                  result = array[0] 
                if(count==1)
                  result = array[0] + ' ' + array[1][0] + '. ' + array[2][0] + '.'
                if(count==2)
                  result = array[0] + ' ' + array[1] + ' ' + array[2][0] + '.'
                break
            
          }
    
        }catch(e){}
        return result
      }
}

export const GlobalFunctions = new Gloabl_()