import {GlobalFunctions} from "../GlobalFunctions"
import {store} from '../store/configureStore'


export function loadUserData_FromStorage(arg) {
  return (dispatch) => {

    let func = (mainObj,key,data)=>{
      const t = mainObj
      let tt = t
      if(data != null){
        tt[key] = data
      }
      return tt
    }
    
    let Data = {}
    Data = func(Data,'login',GlobalFunctions.getCookie('login'))
    Data = func(Data,'password',GlobalFunctions.getCookie('password'))

    dispatch({
      type: 'loadUserData_FromStorage',
      payload:Data
    })
    
    
    global.afterSustemLoad()
    
  }
}

export function logOut(){
  return (dispatch) => {
    dispatch({
      type: 'logOut'
    })
  }
}

export function loginIn(arg) {
  return (dispatch) => {
    
    let userData = store.getState().user.data

    if(userData.login == null || userData.password == null){
      console.error('Данных для входа не обнаружено')
      dispatch({
        type: 'loginIn',
        payload:{
          isOk:false,
          data:{}
        }
      })
      return
    }

    GlobalFunctions.API_task({
      operation:"loginIn",
      block:'Auth',
      login:userData.login,
      password:userData.password
    })
    .then(data => {
      
      let isOk = false
      if(data != null) isOk = true
      try{
        data.IMAGES_URL = JSON.parse(data.IMAGES_URL)
      }catch(e){}
      dispatch({
        type: 'loginIn',
        payload:{
          isOk,
          data:data
        }
      })
      global.afterLoginned()
    }).catch(()=>{
      dispatch({
        type: 'loginIn',
        payload:{
          isOk:false,
          data:{}
        }
      })
    })

    

  }
}