import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { loadUserData_FromStorage,loginIn } from '../actions/UserAct';
import { getVARS,getUsers,getDB_Data,getDB_Data_lazy,getUSERS,getBACKLOG,getCHATS} from '../actions/PageActions';
import Login from "./Login"
import { ToastProvider } from 'react-toast-notifications'
import {BrowserRouter as Router,Switch, Route,Link,Redirect} from "react-router-dom";

import { withCookies } from 'react-cookie';
import Main from './Main';
import {GlobalFunctions as GF} from "../GlobalFunctions"
import "../global"
import { LinearProgress } from '@mui/material';
import Guid from './pages/Guid';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import PopUpImgs from './PopUp/PopUpImgs';
import {openPopImgs} from '../actions/PopUpActions'

class App extends Component {
  constructor(props) {
    super(props)
    this.state={
      appLoaded:false
    }
    const { cookies } = props;

    global.afterSustemLoad = () => {
      //тут описывается то, что будет запущено при старте системы
      this.props.loginIn()
    }

    global.afterLoginned = async () => {
      let DataTables = ['HUMANS','USER_ROLES','USER_STATUS','VARS','STATUSES','DEPTS','USERS']
      let DataShedul = [
            ()=>this.props.getDB_Data('getPROJECTS','PROJECTS'),
            ()=>this.props.getDB_Data_lazy('HUMANS','IMAGES_URL'),
            ()=>this.props.getDB_Data_lazy('CHAT_USERS'),
            ()=>this.props.getDB_Data_lazy('USER_ROLES'),
            ()=>this.props.getDB_Data_lazy('USER_STATUS'),
            ()=>this.props.getDB_Data_lazy('VARS'),
            ()=>this.props.getUSERS(),
            ()=>this.props.getDB_Data_lazy('STATUSES'),
            ()=>this.props.getDB_Data_lazy('DEPTS'),
            
            ()=>this.props.getCHATS(),
            
      ]
      //тут описывается то, что будет запущено после авторизации
      try{
       // this.props.getVARS()
        
        switch(this.props.user.data.ROLE){
          case'ADMN':
            DataTables.concat(['PROJECTS','COMPANYS'])
            DataShedul=DataShedul.concat([
              ()=>this.props.getDB_Data_lazy('COMPANYS'),
              ()=>this.props.getBACKLOG(),
              
            ])
          break;
          case'EMPL':
          DataShedul=DataShedul.concat([
              ()=>this.props.getDB_Data('getCOMPANYS','COMPANYS'),
              ()=>this.props.getBACKLOG(),
            ])
          break;
          case'CLEN':
          DataShedul=DataShedul.concat([
              ()=>this.props.getDB_Data('getCOMPANYS','COMPANYS'),
              ()=>this.props.getBACKLOG(),
            ])
          break;
        }
        
        
      }catch(e){}

      DataShedul.forEach((ds,i)=>{
        setTimeout(()=>ds(),10*i)
      })

      new Promise((resolve,reject)=>{
        DataTables.forEach(async(d,i)=>{
          
          while(this.props.page[d]==null  || this.props.page[d].length==0){
            
            await GF.WaitingTimer(10)
          }
          //console.warn(d+' - LOADED')
          if(i==DataTables.length-1)
            resolve()
        })
        
        
      }).then(()=>{
        setTimeout(()=>{
          this.setState({appLoaded:true})
        },1000)
        
      })
    }

    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }

    props.loadUserData_FromStorage()
    



  }



  

  render() {
    const { user,PopUps} = this.props
    const {appLoaded} = this.state
    let content = null
  
   
    if (user.isLogined == null) {
      content =  null
    } else if (user.isLogined){
      if(appLoaded)
        content =  (
          <div key="rootView"> 
            <Router key="mainRouter" >
              <Switch>
                
              <Route path='/guid/:GUID'>
                <Guid />
              </Route>
                <Route path='/'>
                
                  <Main/>
                </Route>
              
              </Switch>
              
            </Router>
          </div>
        )
      else 
        content =  (
          <div key="rootView"> 
            <LinearProgress/>
          </div>
        )
    }
    else 
    content = (
        <Router key="mainRouter" >
            <Switch>
              
              <Route path='/guid/:GUID'>
                <Guid />
              </Route>
              <Route path='/'>
                <Login />
              </Route>
            </Switch>
            
          </Router>
    )
    
    return (
      <ToastProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        {PopUps.length>0?<BlurBg visible={true}/>:<BlurBg visible={false}/>}

        {PopUps.map(popUp=>{
          return popUp
        })}
     
        {content}
        <PopUpImgs/>
        </LocalizationProvider>
      </ToastProvider>
      
    )

  }
}

const BlurBg = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    opacity:${props=>props.visible?1:0};
    transition: .5s;
    width: 100vw;
    z-index:${props=>props.visible?100:-999};;
`


const mapDispatchToProps = (dispatch) => {
  return {
    loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)), 
    loginIn: (arg) => dispatch(loginIn(arg)), 
    getVARS: (arg) => dispatch(getVARS(arg)), 
    getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

    getBACKLOG: (arg) => dispatch(getBACKLOG(arg)), 
    getUSERS: (arg) => dispatch(getUSERS(arg)), 
    getCHATS: (arg) => dispatch(getCHATS(arg)), 
    openPopImgs: (arg) => dispatch(openPopImgs(arg)), 
  };
};


const mapStateToProps = (store) => {
  return {
    user: store.user,
    page: store.page,
    PopUps: store.popups.windows
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(App));

