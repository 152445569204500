
import { Check } from '@mui/icons-material';
import { Autocomplete, Avatar, Breadcrumbs, Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Select, TextField, Tooltip, Typography,Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import { getDB_Data,getBACKLOG,getDB_Data_lazy,getUSERS } from '../../../../actions/PageActions';
import {GlobalFunctions as GF} from '../../../../GlobalFunctions'





const form_fields = [
    {
        label:'Название',
        name:'TITLE',
    },
    {
        label:'Описание',
        name:'DESCR',
        multiline:true,
        rows:4
    },
    {
        label:'Сайт',
        name:'WEB',
    },
]

class POP_Company extends React.Component {
    constructor(props) {
        super()
        this.state = {
            Self:{}
        }
        this.input=[]
    }

    

    componentDidMount() {
        const {} = this.state
        const {COMPANYS,DEPTS,ID_OBJECT} = this.props

        if(ID_OBJECT!=null){
            var Self_tmp = COMPANYS.find(p=>p.ID==ID_OBJECT)
            if(Self_tmp==null) return
            var Self = {}
            Object.assign(Self,Self_tmp)
            Object.keys(this.input).forEach(key=>{
                this.input[key].value = Self[key]
                
            })
            this.setState({Self})
        }
    }

    Delete(){
        const {COMPANYS,DEPTS,ID_OBJECT,user} = this.props

        GF.API_task({
            operation:'deleteSome',
            Table:'COMPANYS',
            ID:ID_OBJECT
        }).then(res=>{
            switch(user.ROLE){
                case'ADMN':
                    this.props.getDB_Data_lazy('COMPANYS')
                break;
                case'EMPL':
                    this.props.getDB_Data('getCOMPANYS','COMPANYS')
                break;
            }
            
            this.props.closePopUp()
        })
    }


    Save(){
        const {Self} = this.state
        const {COMPANYS,user} = this.props
        if(!GF.checkReqvireds([this.input.TITLE])) return
        let data = {}
        Object.keys(this.input).forEach(key=>{
            try{
                let val = this.input[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })
        
        let reqv = {
            operation:'createUpdateSome',
            Table:'COMPANYS',
            data
        }
        if(this.props.ID_OBJECT !=null)
            reqv.ID = this.props.ID_OBJECT

        GF.API_task(reqv).then(res=>{
            switch(user.ROLE){
                case'ADMN':
                    this.props.getDB_Data_lazy('COMPANYS')
                break;
                case'EMPL':
                    this.props.getDB_Data('getCOMPANYS','COMPANYS')
                break;
            }

            this.props.closePopUp()
        })
    }

    render (){
        const {COMPANYS,user} = this.props
        const {Self} = this.state
       
    
        return (
            <POP>
                <h4>Компания</h4>

                {form_fields.map(input => 
                        <TextField
                            className='w-100 mt-3'
                            margin='dense'
                            disabled={user.ROLE!='ADMN'}
                            size='small'
                            {...input}
                            inputRef={ref=>this.input[input.name]=ref}
                        />
                )}


                <div className='mt-3'/>

                {this.props.ID_OBJECT==null || user.ROLE!='ADMN' || Self.ID==1?null:
                    <Button className='float me-2' color='error' onClick={()=>this.Delete()}>удалить</Button>
                }
                <Button className='float-end' onClick={()=>this.Save()}>Сохранить</Button>

            </POP>
        )

            
      
    }
}

const POP = styled.div`
min-height:100px;
width:500px;
`





const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getBACKLOG:(arg)=>dispatch(getBACKLOG(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 

        getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 

        getUSERS: (arg) => dispatch(getUSERS(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        PROJECTS:store.page.PROJECTS,
        COMPANYS:store.page.COMPANYS,
        user:store.user.data,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_Company);

